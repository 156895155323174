import { Offcanvas } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createLinkValidation } from '../../../utils/validations/creatLinkValidation'
import { useContext, useEffect, useState } from 'react'
import AddNewShortLinkQR from './addNewQr'
import AddNewUTM from './addNewUTM'
import AddNewTitleLink from './addNewTitleLink'
import AddNewTagLinks from './addNewTagLinks'
import AddNewButtons from './addNewButtons'
import ShortLinksAPI from '../../../services/http/shortLinks'
import DestinationURL from './destinationUrl'
import { AuthContxt } from '../../../store/authContxt'
import { ShortLinkContxt } from '../shortLinkContxt'
import DrawerWrapper from '../../wrapper/drawerWrapper'
import formatAndValidateUrl from '../../../utils/formatAndValidateUrl'
import { PLAN } from '../../../utils/enum'
import ShortlinkAnalytics from '../edit-short-link/shortlinkAnalytics'
const BASE_URL = process.env.REACT_APP_QR_BASE_URL
const DEFAULT_VALUES = {
	tags: '',
	url: '',
	title: '',
	destinationUrl: '',
	brandedDomain: BASE_URL,
	slashTag: '',
	expirationDate: '',
	utm_source: '',
	utm_medium: '',
	utm_campaign: '',
	utm_id: '',
	utm_term: '',
	utm_content: '',
	isQrGenerated: false,
	isLinkInBio: false,
	bioPage: '',
	passwordProtectionEnabled: false,
	password: '',
}

const AddNewShortLink = ({
	edittedData,
	isAddNewLinkOpen,
	setIsAddNewLinkOpen,
	setColumnSelected,
	columnSelected,
}) => {
	const { getShortLinks } = useContext(ShortLinkContxt)
	const { fetchQuota, quota } = useContext(AuthContxt)
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)
	const auth_token = localStorage.getItem('auth_token')

	const form = useForm({
		resolver: yupResolver(createLinkValidation),
		defaultValues: DEFAULT_VALUES,
	})
	const { control, handleSubmit, reset } = form
	const [isURLParamBuilderOpen, setIsURLParamBuilderOpen] = useState(false)

	const messageHandler = (msg) => {
		setMessage(msg)
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const isExpired = (date) => {
		if (!date) return false
		const currentDate = new Date()
		const expDate = new Date(date)
		expDate.setHours(23, 59, 59, 999)
		console.log(expDate < currentDate, 'lllllll')
		return expDate < currentDate
	}

	const onSubmit = async (data) => {
		if (data) {
			data.title = data.title.trim()
			setLoading(true)

			// Set ExpirationDate to 90 days from now if it's empty
			if (!data.expirationDate) {
				data.expirationDate = dayjs().add(90, 'day').toISOString() // or any preferred date format
			}

			if (data.tags) {
				const uniqueArray = data.tags
					.split(',')
					.filter((value, index, self) => {
						return value.trim() && self.indexOf(value.trim()) === index
					})
				data.tags = uniqueArray.join(',')
			}

			if (edittedData) {
				ShortLinksAPI.updateShortLink(auth_token, edittedData.id, {
					...data,
					destinationUrl: formatAndValidateUrl(data.destinationUrl),
					faviconUrl: '',
				})
					.then((response) => {
						setIsAddNewLinkOpen(false)
						getShortLinks()
						fetchQuota()
						reset()
					})
					.catch((error) => {
						messageHandler(
							error?.response?.data?.data?.message ||
								error?.response?.data?.message ||
								'Something went wrong'
						)
					})
					.finally(() => {
						setLoading(false)
					})
			} else {
				ShortLinksAPI.addNewShortLink(auth_token, {
					...data,
					destinationUrl: formatAndValidateUrl(data.destinationUrl),
					faviconUrl: '',
				})
					.then((response) => {
						setIsAddNewLinkOpen(false)
						getShortLinks()
						fetchQuota()
						reset()
					})
					.catch((error) => {
						messageHandler(
							error?.response?.data?.data?.message ||
								error?.response?.data?.message ||
								'Something went wrong'
						)
					})
					.finally(() => {
						setLoading(false)
					})
			}
		}
	}

	const header = (
		<>
			<Offcanvas.Header className="px-lg-20 border-bottom" closeButton>
				<h2 className="fw-bold fs-2x my-2">
					{edittedData ? 'Update' : 'Add New'} Link
				</h2>
			</Offcanvas.Header>

			{edittedData &&
			!isExpired(edittedData.expirationDate) &&
			quota?.plan === PLAN.STANDARD ? (
				<div
					className="d-flex justify-content-end mb-3 link-info"
					style={{ marginRight: '2rem' }}
				>
					{`Remaining edit limit for destination url is ${quota?.linkEdit}`}
				</div>
			) : null}
		</>
	)

	useEffect(() => {
		if (edittedData) {
			reset({
				tags: edittedData.tags,
				url: edittedData.destinationUrl,
				title: edittedData.title,
				destinationUrl: edittedData.destinationUrl,
				brandedDomain: edittedData.brandedDomain,
				slashTag: edittedData.slashTag,
				expirationDate: edittedData.expirationDate,
				createdAt: edittedData.createdAt,
				utm_source: edittedData.utm_source,
				utm_medium: edittedData.utm_medium,
				utm_campaign: edittedData.utm_campaign,
				utm_id: edittedData.utm_id,
				utm_term: edittedData.utm_term,
				utm_content: edittedData.utm_content,
				isQrGenerated:
					edittedData.type === 'qr' || edittedData.type === 'qrNbioPage',
				isLinkInBio:
					edittedData.type === 'linkInBio' || edittedData.type === 'qrNbioPage',
				bioPage: edittedData.bioPage,
				passwordProtectionEnabled: edittedData.passwordProtectionEnabled,
				password: '',
			})
		}
	}, [edittedData])

	return (
		<DrawerWrapper
			open={isAddNewLinkOpen}
			toggle={() => {
				reset()
				setColumnSelected('')
				setIsAddNewLinkOpen(false)
			}}
			header={columnSelected === 'title' ? <></> : header}
		>
			{(!edittedData || (edittedData && columnSelected === 'editButton')) && (
				<Form onSubmit={handleSubmit(onSubmit)}>
					<DestinationURL
						form={form}
						setIsURLParamBuilderOpen={setIsURLParamBuilderOpen}
					/>

					<AddNewTitleLink control={control} form={form} />

					<AddNewShortLinkQR control={control} form={form} />

					<AddNewTagLinks control={control} form={form} />

					<div className="d-flex justify-content-end mb-3">
						{message ? (
							<span className="error-text text-danger">{message}</span>
						) : null}
					</div>

					<AddNewButtons
						loading={loading}
						edittedData={edittedData}
						linkIsActive={!isExpired(edittedData?.expirationDate)}
					/>
				</Form>
			)}
			{isURLParamBuilderOpen && (
				<AddNewUTM
					isURLParamBuilderOpen={isURLParamBuilderOpen}
					setIsURLParamBuilderOpen={setIsURLParamBuilderOpen}
					form={form}
				/>
			)}
			{edittedData && columnSelected === 'title' && (
				<ShortlinkAnalytics
					shortlink={`https://${edittedData?.brandedDomain}/${edittedData?.slashTag}`}
				/>
			)}
		</DrawerWrapper>
	)
}

export default AddNewShortLink
