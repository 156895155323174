import React from 'react'
import Modal from 'react-modal'

const PopupModal = ({
	isOpen,
	height = '40%',
	onClose,
	heading,
	btn1_text,
	btn2_text,
	onFirstButtonClick,
	onSecondButtonClick,
	showFirstButton = true,
	children,
}) => {
	return (
		<div>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				ariaHideApp={false}
				style={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
					},
					content: {
						zIndex: 9999999,
						flexDirection: 'column',
						justifyContent: 'center',
						maxWidth: `600px`,
						height: `${height} `,
						margin: 'auto',
						borderRadius: '5px',
						backgroundColor: `white`,
						background: `white`,
						boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
						padding: '12px',
						border: `1px solid #d6dcec`,
						overflow: `hidden`,
					},
				}}
			>
				<div className="position-sticky top-0 z-10 bg-white">
					<div className="float-end cursor-pointer pr-2" onClick={onClose}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M18 6L6 18"
								stroke={`black`}
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M6 6L18 18"
								stroke={`black`}
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>

					<h2 className="border-bottom border-[#d6dcec] py-3 pl-4 font-aviano text-lg font-bold text-uppercase">
						{heading}
					</h2>
				</div>

				{children}

				<div className="position-sticky bottom-0 bg-white  px-6 py-7">
					<div className="d-flex justify-content-center gap-5">
						{showFirstButton && (
							<button
								onClick={onFirstButtonClick}
								className="h-52px  rounded-3 border border-danger text-white bg-danger px-8 font-medium"
								type="button"
							>
								{btn1_text}
							</button>
						)}

						<button
							className="h-52px rounded-3 border border-primary text-white bg-primary px-8 font-medium"
							onClick={onSecondButtonClick}
							type="button"
						>
							{btn2_text}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default PopupModal
