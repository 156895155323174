import React, { useState, useEffect } from 'react'
import { useImmer } from 'use-immer'

import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Card,
	CardTitle,
	CardText,
	Button,
	Dropdown,
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Col,
	Row,
	FormGroup,
	Input,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap'
import classnames from 'classnames'
import mailgunImage from '../../assets/media/integrations/mailgun.png'
import mailersendImage from '../../assets/media/integrations/mailersend.png'
import postmarkImage from '../../assets/media/integrations/postmark.png'
import elasticEmailImage from '../../assets/media/integrations/elasticemail.png'
import sparkpostImage from '../../assets/media/integrations/sparkpost.png'
import sendGridImage from '../../assets/media/integrations/sendgrid.png'
import sendinBlueImage from '../../assets/media/integrations/sendinblue.png'
import twillioImage from '../../assets/media/integrations/twillio.png'
import clicksendImage from '../../assets/media/integrations/clicksend.jpg'
import vonageImage from '../../assets/media/integrations/vonage.svg'
import bulksmsImage from '../../assets/media/integrations/bulksms.png'
import plivoImage from '../../assets/media/integrations/Plivo.png'
import truedialogImage from '../../assets/media/integrations/truedialog.svg'
import IntegrationsAPI from '../../services/http/integration'
import {
	showErrorToast,
	showSuccessToast,
} from '../../components/common/ToastService'

/**
 * @typedef Field
 * @property {string} key
 * @property {string} label
 *
 * @typedef Integration
 * @property {string} name
 * @property {string} smallcase
 * @property {'whatsapp' | 'sms' | 'email'} type
 * @property {string} image
 * @property {string} description
 * @property {Field[]} fields
 */

/**
 * Available Email integrations
 *
 * @type {Integration[]}
 */
const availableEmailIntegrations = [
	{
		name: 'SendGrid',
		smallcase: 'sendgrid',
		type: 'email',
		image: sendGridImage,
		description: 'SendGrid provides cloud-based email services.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
	{
		name: 'Mailgun',
		smallcase: 'mailgun',
		type: 'email',
		image: mailgunImage,
		description: 'Mailgun is an email automation service.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
			{
				key: 'domain',
				label: 'Domain',
			},
		],
	},
	{
		name: 'Brevo',
		smallcase: 'brevo',
		type: 'email',
		image: sendinBlueImage,
		description: 'Brevo provides marketing automation.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
	{
		name: 'MailerSend',
		smallcase: 'mailersend',
		type: 'email',
		image: mailersendImage,
		description: 'MailerSend is an transactional email sending platform',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
			{
				key: 'domain',
				label: 'Domain',
			},
		],
	},
	{
		name: 'Postmark',
		smallcase: 'postmark',
		type: 'email',
		image: postmarkImage,
		description: 'Postmark is an transactional email sending platform',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
	{
		name: 'ElasticEmail',
		smallcase: 'elasticemail',
		type: 'email',
		image: elasticEmailImage,
		description: 'Elastic Email is an transactional email sending platform',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
	{
		name: 'SparkPost',
		smallcase: 'sparkpost',
		type: 'email',
		image: sparkpostImage,
		description: 'SparkPost is an transactional email sending platform',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
]

/**
 * Available SMS integrations
 *
 * @type {Integration[]}
 */
const availableSMSIntegrations = [
	{
		name: 'ClickSend',
		smallcase: 'clicksend',
		type: 'sms',
		image: clicksendImage,
		description:
			'ClickSend is a cloud-based service that lets you send and receive SMS, Email, Voice, and more.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
			{
				key: 'username',
				label: 'Username',
			},
		],
	},
	{
		name: 'Twilio',
		smallcase: 'twilio',
		type: 'sms',
		image: twillioImage,
		description:
			'Twilio offers a cloud communications platform for building SMS, Voice & Messaging applications.',
		fields: [
			{
				key: 'accountSid',
				label: 'Account SID',
			},
			{
				key: 'authToken',
				label: 'Auth Token',
			},
			{
				key: 'fromNumber',
				label: 'From Number',
			},
		],
	},
	{
		name: 'Vonage',
		smallcase: 'vonage',
		type: 'sms',
		image: vonageImage,
		description:
			'Vonage provides a platform for sending and receiving SMS messages globally.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
	{
		name: 'Bulksms',
		smallcase: 'bulksms',
		type: 'sms',
		image: bulksmsImage,
		description:
			'Bulksms offers a platform for sending and receiving bulk SMS messages.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
	{
		name: 'Plivo',
		smallcase: 'plivo',
		type: 'sms',
		image: plivoImage,
		description:
			'Plivo provides a cloud communications platform for SMS and voice calls.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
		],
	},
]

/**
 * Available Whatsapp integrations
 *
 * @type {Integration[]}
 */
const availableWhatsappIntegrations = [
	{
		name: 'Twilio',
		smallcase: 'twilio',
		type: 'whatsapp',
		image: twillioImage,
		description:
			'Twilio offers a cloud communications platform for building Whatsapp, Voice & Messaging applications.',
		fields: [
			{
				key: 'accountSid',
				label: 'Account SID',
			},
			{
				key: 'authToken',
				label: 'Auth Token',
			},
			{
				key: 'fromNumber',
				label: 'From Number',
			},
		],
	},
	{
		name: 'Vonage',
		smallcase: 'vonage',
		type: 'whatsapp',
		image: vonageImage,
		description:
			'Vonage provides a platform for sending and receiving Whatsapp messages globally.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
			{
				key: 'apiSecret',
				label: 'API Secret',
			},
			{
				key: 'toNumber',
				label: 'To Number (To Test credentials validity)',
			},
			{
				key: 'fromNumber',
				label: 'From Number',
			},
		],
	},
	{
		name: '360Dialog',
		smallcase: '360dialog',
		type: 'whatsapp',
		// TODO: Change to correct image
		image: truedialogImage,
		description:
			'360Dialog provides a platform for sending and receiving Whatsapp messages globally.',
		fields: [
			{
				key: 'apiKey',
				label: 'API Key',
			},
			{
				key: 'toNumber',
				label: 'To Number (To Test credentials validity)',
			},
		],
	},

	{
		name: 'Send Pulse',
		smallcase: 'sendpulse',
		type: 'whatsapp',
		// TODO: Change to correct image
		image: truedialogImage,
		description:
			'Send Pulse provides a platform for sending and receiving Whatsapp messages globally.',
		fields: [
			{
				key: 'senderName',
				label: 'Sender Name',
			},
			{
				key: 'clientId',
				label: 'Client ID',
			},
			{
				key: 'clientSecret',
				label: 'Client Secret',
			},
		],
	},
	{
		name: 'Infobip',
		smallcase: 'infobip',
		type: 'whatsapp',
		// TODO: Change to correct image
		image: truedialogImage,
		description:
			'Infobip provides a platform for sending and receiving Whatsapp messages globally.',
		fields: [
			{
				key: 'baseUrl',
				label: 'Base URL',
			},
			{
				key: 'apiKey',
				label: 'API Key',
			},
			{
				key: 'fromNumber',
				label: 'From Number',
			},
		],
	},
	{
		name: 'Gupshup',
		smallcase: 'gupshup',
		type: 'whatsapp',
		// TODO: Change to correct image
		image: truedialogImage,
		description:
			'Gupshup provides a platform for sending and receiving Whatsapp messages globally.',
		fields: [
			{
				key: 'appName',
				label: 'App Name',
			},
			{
				key: 'appId',
				label: 'App ID',
			},
			{
				key: 'apiKey',
				label: 'API Key',
			},
			{
				key: 'fromNumber',
				label: 'From Number',
			},
		],
	},
]

const brandMap = [
	...availableEmailIntegrations,
	...availableSMSIntegrations,
	...availableWhatsappIntegrations,
].reduce((acc, brand) => {
	acc[brand.name.toLowerCase()] = brand.name
	return acc
}, {})

const providerImagesBySmallcase = [
	...availableEmailIntegrations,
	...availableSMSIntegrations,
	...availableWhatsappIntegrations,
].reduce((acc, brand) => {
	acc[brand.smallcase] = brand.image
	return acc
}, {})

const IntegrationsCard = () => {
	const [activeTab, setActiveTab] = useState('1')
	const [dropdownOpen, setDropdownOpen] = useState(null)
	const [modalOpen, setModalOpen] = useState(false)
	const [testSuccess, setTestSuccess] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	/**
	 * @type {[Integration, React.Dispatch<Integration>]} state
	 */
	const [integrationToActivate, setIntegrationToActivate] = useState({})
	/**
	 * @type {[Record<string, string>, React.Dispatch<Record<string, string>>]}
	 */
	const [integrationFieldsInputState, setIntegrationFieldsInputState] =
		useImmer({})

	const [integrations, setIntegrations] = useState([])
	const [isUpdate, setIsUpdate] = useState(false)
	const [currentIntegrationId, setCurrentIntegrationId] = useState(null)

	useEffect(() => {
		if (activeTab === '1') {
			getAllIntegrations()
		}
	}, [activeTab])

	const getAllIntegrations = async () => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			const response = await IntegrationsAPI.getAllIntegrations(auth_token)
			let loadedIntegrations = []
			if (
				response.data &&
				response.data.integrations &&
				response.data.integrations.length > 0
			) {
				loadedIntegrations = response.data.integrations.map((integration) => {
					return {
						...integration,
						image: providerImagesBySmallcase[integration.name],
					}
				})
			}
			setIntegrations(loadedIntegrations || [])
		} catch (error) {
			console.error('There was an error fetching all integrations!', error)
		}
	}

	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const toggleDropdown = (index) => {
		setDropdownOpen(dropdownOpen === index ? null : index)
	}

	const toggleModal = () => {
		setModalOpen(!modalOpen)
		setTestSuccess(false)
	}

	const handleActivateClick = (brand) => {
		if (
			integrations &&
			integrations.length > 0 &&
			integrations.some(
				(integration) =>
					integration.name.toLowerCase() === brand.name.toLowerCase()
			)
		) {
			showErrorToast(`${brand.name} is already configured`, 3000)
			return
		}
		setIntegrationToActivate(brand)
		setCurrentIntegrationId(null)
		setIsUpdate(false)
		toggleModal()
	}

	/**
	 * Handle change input of integration which activating an integration
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event Change event
	 */
	const handleIntegrationFieldInputChange = (key, event) => {
		setIntegrationFieldsInputState((previousState) => {
			previousState[key] = event.target.value
		})
	}

	const handleDeleteClick = async (integrationId) => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			await IntegrationsAPI.deleteIntegration(auth_token, integrationId)
			setIntegrations(
				integrations.filter((integration) => integration.id !== integrationId)
			)
		} catch (error) {
			console.error('Error deleting integration:', error)
		}
	}

	const testConnection = async () => {
		if (!validateIntegrationInputs()) {
			return
		}
		setIsLoading(true)
		try {
			const auth_token = localStorage.getItem('auth_token')
			const response = await IntegrationsAPI.testIntegration(auth_token, {
				type: integrationToActivate.type,
				provider: integrationToActivate.smallcase.toLowerCase(),
				credentials: integrationFieldsInputState,
			})
			if (response.status === 200) {
				setTestSuccess(true)
				showSuccessToast('Test connection is successful', 2000)
			} else {
				showErrorToast(
					'Test connection is failed. Please check your keys and the access',
					2000
				)
				setTestSuccess(false)
			}
		} catch (error) {
			console.error('Error testing connection:', error)
			showErrorToast('Unexpected error happened', 2000)
			setTestSuccess(false)
		} finally {
			setIsLoading(false)
		}
	}

	const validateIntegrationInputs = () =>
		integrationToActivate.fields.every(
			(field) => integrationFieldsInputState[field.key] !== ''
		)

	const handleSave = async () => {
		if (!validateIntegrationInputs()) {
			return
		}

		try {
			const name = integrationToActivate.name
			const auth_token = localStorage.getItem('auth_token')
			await IntegrationsAPI.saveIntegration(auth_token, {
				type: integrationToActivate.type,
				provider: integrationToActivate.smallcase,
				credentials: integrationFieldsInputState,
			})
			toggleModal()
			showSuccessToast(`${name} saved successfully`, 2000)
		} catch (error) {
			console.error('Error saving integration:', error)
			showErrorToast('Something went wrong!', 5000)
		}
	}

	const handleUpdate = async () => {
		if (!validateIntegrationInputs()) {
			return
		}
		try {
			const auth_token = localStorage.getItem('auth_token')
			const response = await IntegrationsAPI.updateIntegration(
				auth_token,
				currentIntegrationId,
				{
					type: integrationToActivate.type,
					name: integrationToActivate.smallcase,
					credentials: integrationFieldsInputState,
				}
			)
			setIntegrations(
				integrations.map((integration) =>
					integration.id === currentIntegrationId
						? {
								...response.data,
								image: providerImagesBySmallcase[response.data.name],
							}
						: integration
				)
			)
			toggleModal()
		} catch (error) {
			console.error('Error updating integration:', error)
		}
	}

	return (
		<div className="container mt-5">
			<Nav tabs>
				<NavItem className="cursor-pointer">
					<NavLink
						className={classnames({ active: activeTab === '1' })}
						onClick={() => {
							toggleTab('1')
						}}
					>
						My Integrations
					</NavLink>
				</NavItem>
				<NavItem className="cursor-pointer">
					<NavLink
						className={classnames({ active: activeTab === '2' })}
						onClick={() => {
							toggleTab('2')
						}}
					>
						Email Integrations
					</NavLink>
				</NavItem>
				<NavItem className="cursor-pointer">
					<NavLink
						className={classnames({ active: activeTab === '3' })}
						onClick={() => {
							toggleTab('3')
						}}
					>
						SMS Integrations
					</NavLink>
				</NavItem>
				<NavItem className="cursor-pointer">
					<NavLink
						className={classnames({ active: activeTab === '4' })}
						onClick={() => {
							toggleTab('4')
						}}
					>
						WhatsApp Integrations
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent className="mt-5" activeTab={activeTab}>
				<TabPane tabId="1">
					{integrations.filter((integration) => integration.type === 'email')
						.length > 0 && (
						<>
							<h3 className="mb-4 text-center">Email Integrations</h3>
							<div className="row">
								{integrations
									.filter((integration) => integration.type === 'email')
									.map((integration) => (
										<div key={integration.id} className="col-md-4 col-12 mb-4">
											<Card className="border-gray-250 border rounded-2 p-3">
												<div className="d-flex justify-content-between">
													<div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
														<img
															className="w-100"
															src={integration.image}
															alt={brandMap[integration.name]}
														/>
													</div>
													<Dropdown
														isOpen={dropdownOpen === integration.id}
														toggle={() => toggleDropdown(integration.id)}
													>
														<DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
															<i className="fas fa-ellipsis text-gray-800 fs-2"></i>
														</DropdownToggle>
														<DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
															{/* <DropdownItem
                                className="bg-hover-light-info"
                                onClick={() => handleUpdateClick(integration)}
                              >
                                Update
                              </DropdownItem> */}
															<DropdownItem
																className="bg-hover-light-info"
																onClick={() =>
																	handleDeleteClick(integration.id)
																}
															>
																Deactivate
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
												</div>
												<div className="pt-lg-8 pt-4">
													<CardTitle tag="h2" className="mb-5">
														{brandMap[integration.name]}
													</CardTitle>
												</div>
											</Card>
										</div>
									))}
							</div>
						</>
					)}
					{integrations.filter((integration) => integration.type === 'sms')
						.length > 0 && (
						<>
							<h3 className="text-center">SMS Integrations</h3>
							<div className="row">
								{integrations
									.filter((integration) => integration.type === 'sms')
									.map((integration) => (
										<div
											key={integration.id}
											className="col-md-4 col-12 rounded-2 pb-10 px-5"
										>
											<Card className="border-gray-250 border rounded-2 p-8">
												<div className="d-flex justify-content-between">
													<div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
														<img
															className="w-100"
															src={integration.image}
															alt={brandMap[integration.name]}
														/>
													</div>
													<Dropdown
														isOpen={dropdownOpen === integration.id}
														toggle={() => toggleDropdown(integration.id)}
													>
														<DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
															<i className="fas fa-ellipsis text-gray-800 fs-2"></i>
														</DropdownToggle>
														<DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
															{/* <DropdownItem
                                className="bg-hover-light-info"
                                onClick={() => handleUpdateClick(integration)}
                              >
                                Edit
                              </DropdownItem> */}
															<DropdownItem
																className="bg-hover-light-info"
																onClick={() =>
																	handleDeleteClick(integration.id)
																}
															>
																Deactivate
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
												</div>
												<div className="pt-lg-8 pt-4">
													<CardTitle tag="h2" className="mb-5">
														{brandMap[integration.name]}
													</CardTitle>
												</div>
											</Card>
										</div>
									))}
							</div>
						</>
					)}
					{integrations.filter((integration) => integration.type === 'whatsapp')
						.length > 0 && (
						<>
							<h3 className="text-center">WhatsApp Integrations</h3>
							<div className="row">
								{integrations
									.filter((integration) => integration.type === 'whatsapp')
									.map((integration) => (
										<div
											key={integration.id}
											className="col-md-4 col-12 rounded-2 pb-10 px-5"
										>
											<Card className="border-gray-250 border rounded-2 p-8">
												<div className="d-flex justify-content-between">
													<div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
														<img
															className="w-100"
															src={integration.image}
															alt={brandMap[integration.name]}
														/>
													</div>
													<Dropdown
														isOpen={dropdownOpen === integration.id}
														toggle={() => toggleDropdown(integration.id)}
													>
														<DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
															<i className="fas fa-ellipsis text-gray-800 fs-2"></i>
														</DropdownToggle>
														<DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
															<DropdownItem
																className="bg-hover-light-info"
																onClick={() =>
																	handleDeleteClick(integration.id)
																}
															>
																Deactivate
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
												</div>
												<div className="pt-lg-8 pt-4">
													<CardTitle tag="h2" className="mb-5">
														{brandMap[integration.name]}
													</CardTitle>
												</div>
											</Card>
										</div>
									))}
							</div>
						</>
					)}
					{integrations.filter((integration) => integration.type === 'email')
						.length === 0 &&
						integrations.filter((integration) => integration.type === 'sms')
							.length === 0 &&
						integrations.filter(
							(integration) => integration.type === 'whatsapp'
						).length === 0 && (
							<p className="text-center">No integrations available.</p>
						)}
				</TabPane>
				<TabPane tabId="2">
					<div className="row">
						{availableEmailIntegrations.map((brand, index) => (
							<div
								className="col-md-4 col-12 rounded-2 pb-10 px-5"
								key={brand.name}
							>
								<Card className="border-gray-250 border rounded-2 p-8">
									<div className="d-flex justify-content-between">
										<div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
											<img
												className="w-100"
												src={brand.image}
												alt={brand.name}
											/>
										</div>
										<Dropdown
											isOpen={dropdownOpen === index + 100}
											toggle={() => toggleDropdown(index + 100)}
										>
											<DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
												<i className="fas fa-ellipsis text-gray-800 fs-2"></i>
											</DropdownToggle>
											<DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
												<DropdownItem
													className="bg-hover-light-info"
													onClick={() => handleActivateClick(brand)}
												>
													Activate
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
									</div>
									<div className="pt-lg-8 pt-4">
										<CardTitle tag="h2" className="mb-5">
											{brand.name}
										</CardTitle>
										<CardText className="fw-semibold fs-5 text-gray-700">
											{brand.description}
										</CardText>
									</div>
								</Card>
							</div>
						))}
					</div>
				</TabPane>
				<TabPane tabId="3">
					<div className="row">
						{availableSMSIntegrations.map((brand, index) => (
							<div
								className="col-md-4 col-12 rounded-2 pb-10 px-5"
								key={brand.name}
							>
								<Card className="border-gray-250 border rounded-2 p-8">
									<div className="d-flex justify-content-between">
										<div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
											<img
												className="w-100"
												src={brand.image}
												alt={brand.name}
											/>
										</div>
										<Dropdown
											isOpen={dropdownOpen === index + 200}
											toggle={() => toggleDropdown(index + 200)}
										>
											<DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
												<i className="fas fa-ellipsis text-gray-800 fs-2"></i>
											</DropdownToggle>
											<DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
												<DropdownItem
													className="bg-hover-light-info"
													onClick={() => handleActivateClick(brand)}
												>
													Activate
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
									</div>
									<div className="pt-lg-8 pt-4">
										<CardTitle tag="h2" className="mb-5">
											{brand.name}
										</CardTitle>
										<CardText className="fw-semibold fs-5 text-gray-700">
											{brand.description}
										</CardText>
									</div>
								</Card>
							</div>
						))}
					</div>
				</TabPane>
				<TabPane tabId="4">
					<div className="row">
						{availableWhatsappIntegrations.map((brand, index) => (
							<div
								className="col-md-4 col-12 rounded-2 pb-10 px-5"
								key={brand.name}
							>
								<Card className="border-gray-250 border rounded-2 p-8">
									<div className="d-flex justify-content-between">
										<div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
											<img
												className="w-100"
												src={brand.image}
												alt={brand.name}
											/>
										</div>
										<Dropdown
											isOpen={dropdownOpen === index + 300}
											toggle={() => toggleDropdown(index + 300)}
										>
											<DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
												<i className="fas fa-ellipsis text-gray-800 fs-2"></i>
											</DropdownToggle>
											<DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
												<DropdownItem
													className="bg-hover-light-info"
													onClick={() => handleActivateClick(brand)}
												>
													Activate
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
									</div>
									<div className="pt-lg-8 pt-4">
										<CardTitle tag="h2" className="mb-5">
											{brand.name}
										</CardTitle>
										<CardText className="fw-semibold fs-5 text-gray-700">
											{brand.description}
										</CardText>
									</div>
								</Card>
							</div>
						))}
					</div>
				</TabPane>
			</TabContent>
			<Modal
				isOpen={modalOpen}
				toggle={toggleModal}
				className="custom-modal-width"
			>
				<ModalHeader
					className="mb-4 mx-4 mt-2 border-bottom"
					toggle={toggleModal}
				>
					<h3 className="font-weight-bold">
						{isUpdate ? 'Update API KEY' : 'Add API KEY'}
					</h3>
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col md={12}>
							{integrationToActivate.fields?.map((field) => (
								<FormGroup key={field.key}>
									<Label className="fs-5 fw-bold mb-2">{field.label}</Label>
									<Input
										type="text"
										className="form-control w-100 rounded-1 h-50px mb-5"
										value={integrationFieldsInputState[field.key]}
										onChange={(e) =>
											handleIntegrationFieldInputChange(field.key, e)
										}
									/>
								</FormGroup>
							))}
						</Col>
						<Col md={12} className="text-end">
							<Button
								color="secondary"
								className="rounded-1 me-2"
								onClick={testConnection}
								disabled={isLoading}
							>
								{isLoading ? 'Testing...' : 'Test Connection'}
							</Button>
							<Button
								color="info"
								className="rounded-1"
								disabled={!testSuccess}
								onClick={isUpdate ? handleUpdate : handleSave}
							>
								{isUpdate ? 'Update Sender' : 'Save Sender'}
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</div>
	)
}

export default IntegrationsCard
