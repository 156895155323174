import { useContext, useEffect, useState } from 'react'
import SubscriptionApi from '../../../../services/http/subscription'
import dayjs from 'dayjs'
import { AuthContxt } from '../../../../store/authContxt'
import { Link } from 'react-router-dom'
import { MONTHLY_STARTER } from '../../../../utils/planLookUpKeys'
import capitalizeFirstLetter from '../../../../utils/captilizeFirstLetter'
import { PLAN } from '../../../../utils/enum'

const Index = () => {
	const { profile, quota } = useContext(AuthContxt)
	const [defaultPlanQuota, setDefaultPlanQuota] = useState({})
	const [planPrice, setPlanPrice] = useState({})
	const [latestBill, setLatestBill] = useState({})

	const selectedPlanType = profile?.plan?.split('-')[1]
	useEffect(() => {
		if (selectedPlanType) {
			const fetchActivity = async () => {
				try {
					const defaultQuota =
						await SubscriptionApi.getDefaultPlan(selectedPlanType)
					if (!defaultQuota.data.error) {
						setDefaultPlanQuota(defaultQuota?.data?.data)
					}

					const currentPlanPrice =
						await SubscriptionApi.getPlanPrice(selectedPlanType)
					if (!currentPlanPrice.data.error) {
						setPlanPrice(currentPlanPrice?.data?.data)
					}
				} catch (e) {
					console.log('Error while fetching default plan: ', e)
				}
			}

			fetchActivity()
		}
	}, [selectedPlanType])

	useEffect(() => {
		const fetchLatestBill = async () => {
			try {
				const response = await SubscriptionApi.getLatestBill()
				console.log({ response })
				if (response?.data?.data) {
					setLatestBill(response?.data?.data)
				} else {
					setLatestBill(null)
				}
			} catch (e) {
				console.log('Error while fetching latest bill : ', e)
			}
		}
		fetchLatestBill()
	}, [])

	const { plan = MONTHLY_STARTER } = profile || {}
	const isMon = plan.includes('monthly')
	const { monthlyPrice = 0, yearlyPrice = 0, currency = '$' } = planPrice || {}

	const calculatedPrice = `${currency}${isMon ? monthlyPrice : yearlyPrice}`

	const {
		shortlink: defaultShortlink = 0,
		qrCode: defaultQrCode = 0,
		linkInBioPages: defaultlinkInBioPages = 0,
		connectedDomain: defaultConnectedDomain = 0,
		user: defaultUser = 0,
	} = defaultPlanQuota || {}
	const {
		shortlink = 0,
		connectedDomain = 0,
		user = 0,
		qrCode,
		linkInBioPages,
	} = quota || {}

	return (
		<div className="card  mb-5 mb-xl-10">
			<div className="card-body py-lg-9 p-5 px-lg-10">
				<div className="row g-10">
					<div className="col-lg-7">
						<h3 className="mb-2">{`Active till ${
							plan?.includes('starter')
								? 'Life Time'
								: dayjs(profile?.subEndDate || new Date()).format(
										'MMM DD, YYYY'
									)
						}`}</h3>

						<p className="fs-6 text-gray-600 fw-semibold mb-6 mb-lg-15">
							{!plan?.includes('starter')
								? 'We will send you a notification upon Subscription expiration'
								: ''}
						</p>

						<div>
							<div className="fs-5 mb-2">
								<span className="text-gray-800 fw-bold me-1">
									{calculatedPrice}
								</span>
								<span className="text-gray-600 fw-semibold">{`Per ${isMon ? 'Month' : 'Year'}`}</span>
							</div>

							<div className="fs-6 text-gray-600 fw-semibold">
								{selectedPlanType === PLAN.STARTER
									? 'Extended To Standard Plan. Up to 300 Short Links/mon'
									: null}
								{selectedPlanType === PLAN.STANDARD
									? 'Extended To Super Plan. Up to 2000 Short Links/mon'
									: null}
							</div>
						</div>

						<div className="mt-6 mt-lg-15">
							{selectedPlanType !== PLAN.STARTER ? (
								<>
									{' '}
									<div className="fs-5 mb-2">
										<span className="text-gray-800 fw-bold me-1">
											Last Payment
										</span>
									</div>
									<div className="fs-lg-6 text-gray-600 fw-semibold d-flex align-items-center justify-content-start">
										{latestBill ? (
											<span className="fw-semibold">
												{`${dayjs(latestBill?.createdAt || new Date()).format(
													'MMM DD, YYYY'
												)}.`}
												&nbsp;
											</span>
										) : null}
										<span className="fw-semibold">
											{` ${capitalizeFirstLetter(selectedPlanType)} Plan.`}
											&nbsp;
										</span>
										<span className="fw-bold text-success">
											{calculatedPrice}
										</span>
									</div>
								</>
							) : null}
						</div>
					</div>

					<div className="col-lg-5">
						<div>
							<div className="d-flex text-muted fw-bold fs-5 mb-3">
								<span className="flex-grow-1 text-gray-800">Short Links</span>
								<span className="text-gray-800">{`${
									defaultShortlink - shortlink
								} of ${defaultShortlink} Created`}</span>
							</div>

							<div className="progress h-8px bg-light-info mb-2">
								<div
									className="progress-bar bg-info"
									role="progressbar"
									style={{
										width: `${
											((defaultShortlink - shortlink) / defaultShortlink) * 100
										}%`,
									}}
								></div>
							</div>

							<div className="fs-6 text-gray-600 fw-semibold mb-10">
								{`${shortlink} Short Links remaining in your plan`}
							</div>
						</div>

						<div>
							<div className="d-flex text-muted fw-bold fs-5 mb-3">
								<span className="flex-grow-1 text-gray-800">QR Codes</span>
								<span className="text-gray-800">{`${
									defaultQrCode - qrCode
								} of ${defaultQrCode} Created`}</span>
							</div>

							<div className="progress h-8px bg-light-info mb-2">
								<div
									className="progress-bar bg-info"
									role="progressbar"
									style={{
										width: `${
											((defaultQrCode - qrCode) / defaultQrCode) * 100
										}%`,
									}}
								></div>
							</div>

							<div className="fs-6 text-gray-600 fw-semibold mb-10">
								{`${qrCode} QR codes remaining in your plan`}
							</div>
						</div>

						<div>
							<div className="d-flex text-muted fw-bold fs-5 mb-3">
								<span className="flex-grow-1 text-gray-800">
									Link In Bio Pages
								</span>
								<span className="text-gray-800">{`${
									defaultlinkInBioPages - linkInBioPages
								} of ${defaultlinkInBioPages} Created`}</span>
							</div>

							<div className="progress h-8px bg-light-info mb-2">
								<div
									className="progress-bar bg-info"
									role="progressbar"
									style={{
										width: `${
											((defaultlinkInBioPages - linkInBioPages) /
												defaultlinkInBioPages) *
											100
										}%`,
									}}
								></div>
							</div>

							<div className="fs-6 text-gray-600 fw-semibold mb-10">
								{`${linkInBioPages} Link In Bio pages remaining in your plan`}
							</div>
						</div>

						<div>
							<div className="d-flex text-muted fw-bold fs-5 mb-3">
								<span className="flex-grow-1 text-gray-800">Custom Donaim</span>
								<span className="text-gray-800">{`${
									defaultConnectedDomain - connectedDomain
								} of ${defaultConnectedDomain} Added`}</span>
							</div>

							<div className="progress h-8px bg-light-info mb-2">
								<div
									className="progress-bar bg-info"
									role="progressbar"
									style={{
										width: `${
											((defaultConnectedDomain - connectedDomain) /
												defaultConnectedDomain) *
											100
										}%`,
									}}
								></div>
							</div>

							<div className="fs-6 text-gray-600 fw-semibold mb-10">
								{`${connectedDomain} Domains remaining in your Plan`}
							</div>
						</div>
						<div>
							<div className="d-flex text-muted fw-bold fs-5 mb-3">
								<span className="flex-grow-1 text-gray-800">Users</span>
								<span className="text-gray-800">{`${
									defaultUser - user
								} of ${defaultUser} Used`}</span>
							</div>

							<div className="progress h-8px bg-light-info mb-2">
								<div
									className="progress-bar bg-info"
									role="progressbar"
									style={{
										width: `${((defaultUser - user) / defaultUser) * 100}%`,
									}}
								></div>
							</div>

							<div className="fs-6 text-gray-600 fw-semibold">
								{`${user} Users remaining in your Plan `}
								<Link to={'/subscription'} className="text-info ms-3">
									Want more users
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Index
