import { useContext, useState } from 'react'
import DeleteModal from '../../../../modals/deleteModal'
import UserSettingsApi from '../../../../services/http/userSettings'
import { Spinner } from 'reactstrap'
import { UserSettingsContxt } from '../../userSettingContxt'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
const AddressCard = ({ address, addressHandler = () => {}, page }) => {
	const { billingAddressHandler, selectedAddress, setSelectedAddress } =
		useContext(UserSettingsContxt)
	const [loading, setLoading] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)

	const {
		addressLine1 = '',
		addressLine2 = '',
		town = '',
		state = '',
		postCode = '',
		primary,
		index,
	} = address

	const deleteAddressHandler = async () => {
		try {
			setDeleteModal(false)
			setLoading(true)
			const { data } = await UserSettingsApi.deleteAddress(address?.id)
			if (data?.data) {
				addressHandler('delete', address)
			}
			setLoading(false)
		} catch (e) {
			console.log('Error while deleting address: ', e)
			setLoading(false)
		}
	}

	const primaryAddressHandler = async () => {
		try {
			if (!primary) {
				const response = await UserSettingsApi.markAddressPrimary(address?.id, {
					primary: !primary,
				})
				if (!response?.data?.error) {
					billingAddressHandler('updatePrimary', address)
				}
			}
		} catch (e) {
			console.log('Error while making address primary', e)
		}
	}

	const editAddressHandler = () => {
		addressHandler('update', address)
	}

	const cardClickHandler = () => {
		if (page === 'subscription') {
			setSelectedAddress(address?.id)
		}
	}

	return (
		<>
			<div className="col-xl-6">
				<div
					className={`card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-lg-6 p-5 addresscard`}
					style={
						page === 'subscription' && selectedAddress === address?.id
							? { backgroundColor: '#f8f5ff' }
							: {}
					}
					onClick={cardClickHandler}
				>
					<div className="d-flex flex-column py-2">
						<div
							className="d-flex align-items-center fs-5 fw-bold mb-3 cursor-pointer"
							onClick={(e) => {
								e.stopPropagation()
								primaryAddressHandler()
							}}
						>
							{`Address ${index}`}
							{primary ? (
								<span className="badge bg-light-info text-info ms-3">
									Primary
								</span>
							) : null}
						</div>

						<div
							className="fs-6 fw-semibold text-gray-600"
							style={{ maxWidth: '250px' }}
						>
							{/* Tooltip for Address Line 1 */}
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip id="tooltip-addressLine1">{addressLine1}</Tooltip>
								}
							>
								<div className="text-truncate" style={{ cursor: 'pointer' }}>
									{addressLine1}
								</div>
							</OverlayTrigger>
							<br />
							{/* Tooltip for Address Line 2 */}
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip id="tooltip-addressLine2">{addressLine2}</Tooltip>
								}
							>
								<div className="text-truncate" style={{ cursor: 'pointer' }}>
									{addressLine2}
								</div>
							</OverlayTrigger>
							<br />
							{/* Remaining address without tooltips */}
							{`${town} ${postCode}`}
							<br />
							{state}
						</div>
					</div>

					<div className="d-flex align-items-center py-2">
						<button
							onClick={(e) => {
								e.stopPropagation()
								editAddressHandler()
							}}
							type="button"
							className="btn btn-sm btn-light btn-active-light-info border border-gray-200 bg-white-sm me-3"
						>
							Edit
						</button>
						<button
							type="button"
							disabled={loading}
							onClick={(e) => {
								e.stopPropagation()
								setDeleteModal(true)
							}}
							className="btn btn-sm btn-light-danger bg-danger-sm mt-0"
						>
							{loading ? <Spinner /> : 'Delete'}
						</button>
					</div>
				</div>
			</div>

			<DeleteModal
				isOpen={deleteModal}
				modalHandler={() => setDeleteModal(false)}
				leftBtnHandler={deleteAddressHandler}
				rightBtnHandler={() => setDeleteModal(false)}
				data={{
					heading: 'Delete Address',
					description: 'Are you sure you want to delete this address ?',
					leftBtn: 'Yes, delete it',
					rightBtn: 'Cancel',
				}}
			/>
		</>
	)
}

export default AddressCard
