import React, { useContext, useState, useEffect } from 'react'
import { FormGroup, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import ShareDropdown from './shareDropDown'
import DeleteModal from '../../../modals/deleteModal'
import ShortLinksAPI from '../../../services/http/shortLinks'
import FaviconImage from '../favicon'
import dateFormate from '../../../utils/dateFormat'
import Copy from '../../common/copy'
import { ShortLinkContxt } from '../shortLinkContxt'
import { Tooltip } from 'reactstrap'
import Swal from 'sweetalert2'

const ShortLink = ({ list, setSelectedLinks, selectedLinks, editHandler }) => {
	// Delete Link States
	const [deleteLoading, setDeleteLoading] = useState(false)
	const [deleteModal, setDeleteModal] = useState()
	const { deleteLinkHandler, starredLinkHandler, setShortLinks } =
		useContext(ShortLinkContxt)
	const [logoutTooltipOpen, setlogoutTooltipOpen] = useState(false)
	const togglelogoutTooltip = () => setlogoutTooltipOpen(!logoutTooltipOpen)
	const [isError, setError] = useState('')

	// Starred Toggle States
	const [starredLoading, setStarredLoading] = useState(false)

	// show message
	const handleShowMessage = () => {
		Swal.fire({
			text: 'The URL cannot be enabled as it is blocked by the admin',
			icon: 'error',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#7239ea',
			showConfirmButton: false,
			cancelButtonText: 'Ok got it!',
		})
	}

	const handlechangeStatus = async (id, status) => {
		if (id) {
			if (list?.isadminblocked) {
				handleShowMessage()
				return
			}
			const auth_token = localStorage.getItem('auth_token')
			const requestData = {
				isActive: !status,
			}
			ShortLinksAPI.changeStatus(auth_token, id, requestData, setError)
				.then((data) => {
					setShortLinks((preState) => {
						return preState.map((item) =>
							item.id === id ? { ...item, isActive: !status } : item
						)
					})
				})
				.catch((error) => {
					console.log('Error while changing shortlink status:', error)
				})
		}
	}

	const isExpired = (date) => {
		const currentDate = new Date()
		const expDate = new Date(date)
		expDate.setHours(23, 59, 59, 999)
		return expDate < currentDate
	}

	return (
		<>
			<tr className="link-row border-bottom" key={`${list.id}`}>
				<td className="ps-5">
					<div>
						{selectedLinks.includes(list) && (
							<div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
						)}
						<div className="form-check form-check-sm form-check-custom form-check-info form-check-solid not-checked-white rounded-1">
							<input
								type="checkbox"
								className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
								value={list.id}
								checked={selectedLinks.includes(list)}
								onChange={(e) =>
									setSelectedLinks(
										e.target.checked
											? [...selectedLinks, list]
											: selectedLinks.filter((p) => p !== list)
									)
								}
							/>
						</div>
					</div>
				</td>
				<td>
					<div className="d-flex justify-content-start align-items-center">
						<div className="symbol rounded-1 symbol-25px">
							<FaviconImage list={list} />
						</div>

						<div className="ms-5">
							<div
								onClick={() => editHandler(list, 'title')}
								className={`text-gray-700 fw-bold fs-4 cursor-pointer first-letter-capitalize ${
									!isExpired(list.expirationDate)
										? 'text-hover-info'
										: 'pe-none'
								}`}
							>
								{list.title}
							</div>
						</div>
					</div>
				</td>
				<td className="text-start">
					<Link
						to={`https://${list?.brandedDomain}/${list?.slashTag}`}
						target="_blank"
						className={`text-info fw-bold d-flex align-items-center w-200px customTextWidth ${
							!isExpired(list.expirationDate) ? 'text-hover-info' : 'pe-none'
						}`}
						rel="noreferrer"
					>
						<div>
							<span className="text-wrap-oneline">{`${list?.brandedDomain}/${list?.slashTag}`}</span>
						</div>

						{list.passwordProtectionEnabled ? (
							<>
								<i
									className="fas fa-lock ms-2 text-gray-300"
									Tooltip="Password Protected"
								></i>
							</>
						) : (
							<></>
						)}
					</Link>
				</td>
				<td className="text-center">{list.clicks}</td>
				<td className="text-center">
					<FormGroup switch className="p-0">
						<div class="form-check form-switch form-check-custom form-check-success form-check-solid px-5">
							<Input
								type="switch"
								checked={list.isActive}
								className="cursor-pointer mx-auto"
								onChange={() => handlechangeStatus(list.id, list.isActive)}
								style={{ height: '19px', width: '35px' }}
								// disabled={isExpired(list.expirationDate) || list.isadminblocked}
							/>
						</div>
					</FormGroup>
				</td>

				<td className="text-start">{dateFormate(list.createdAt)}</td>
				<td className="text-start">
					{isExpired(list.expirationDate) ? (
						<>
							<div id="logouttooltip">Expired</div>
							<Tooltip
								placement="top"
								isOpen={logoutTooltipOpen}
								autohide={false}
								target="logouttooltip"
								toggle={togglelogoutTooltip}
							>
								{`Expired on ${dateFormate(list.expirationDate)}`}
							</Tooltip>
						</>
					) : (
						<div>{dateFormate(list.expirationDate)}</div>
					)}
				</td>
				<td className="text-start w-fit-content text-black">
					<div className="w-fit-content d-flex align-items-center">
						<button
							type="button"
							className="btn px-0 py-0"
							onClick={async () => {
								setStarredLoading(true)
								await starredLinkHandler(list?.id, list.isStarred)
								setStarredLoading(false)
							}}
							disabled={starredLoading || isExpired(list.expirationDate)}
						>
							<i
								className={`fa-star fs-2 cursor-pointer ${
									list.isStarred
										? 'fa-solid'
										: 'fa-regular on-hvr-yellow text-warning'
								}`}
								style={{ color: list.isStarred ? 'gold' : '' }}
							></i>
						</button>
						<button
							type="button"
							className="btn px-0 py-0"
							disabled={isExpired(list.expirationDate)}
						>
							<ShareDropdown list={list} />
						</button>
						<button
							type="button"
							className="btn px-0 py-0"
							disabled={isExpired(list.expirationDate)}
						>
							<Copy copyData={`${list?.brandedDomain}/${list?.slashTag}`} />
						</button>
						<button
							type="button"
							className="btn px-0 py-0"
							// disabled={isExpired(list.expirationDate)}
						>
							<i
								className="fa-regular fa-pen-to-square fs-2 ms-5 text-gray-700 cursor-pointer"
								title="Edit"
								onClick={() => editHandler(list, 'editButton')}
							></i>
						</button>
						<button
							type="button"
							className="btn px-0 py-0"
							onClick={() => setDeleteModal(true)}
							disabled={deleteLoading}
						>
							<i
								style={{ marginTop: '-4px' }}
								title="Delete"
								className="fa-regular fa-trash-can fs-2 ms-5 text-danger cursor-pointer"
							/>
						</button>
					</div>
				</td>
			</tr>

			{/* Modals */}
			<DeleteModal
				isOpen={deleteModal}
				modalHandler={() => setDeleteModal(false)}
				leftBtnHandler={async () => {
					setDeleteLoading(true)
					setDeleteModal(false)
					await deleteLinkHandler(list.id)
					setDeleteLoading(false)
				}}
				rightBtnHandler={() => setDeleteModal(false)}
				data={{
					heading: 'Delete short link',
					description: 'Are you sure you want to delete this short link?',
					leftBtn: 'Yes, delete it',
					rightBtn: 'Cancel',
				}}
			/>
		</>
	)
}

export default ShortLink
