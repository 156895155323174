import { Input, Spinner } from 'reactstrap'
import { Controller } from 'react-hook-form'
import { useContext } from 'react'
import { AuthContxt } from '../../store/authContxt'
import { PLAN } from '../../utils/enum'

const InputField = ({
	loading,
	form,
	submitHandler,
	fieldName,
	editMode,
	editModeHandler,
	utmParams = '',
	editCount = null,
}) => {
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
	} = form

	const fieldValue = watch(fieldName)
	const { quota } = useContext(AuthContxt)

	const notUpgradedEditDestinationUrl =
		quota?.plan === PLAN.STARTER || quota?.linkEdit === 0
	return (
		<div className="d-flex align-items-center ms-2">
			{editMode ? (
				<>
					<div className="d-flex align-items-center">
						<Controller
							autocomplete="one-time-code"
							name={fieldName}
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									type={fieldName === 'password' ? 'password' : 'text'}
									autocomplete="one-time-code"
								/>
							)}
						/>
						<button
							disabled={loading}
							className="btn btn-icon btn-circle btn-active-color-info w-25px h-25px bg-body ms-2 show-input-edit-title-btn"
							onClick={handleSubmit(async () => {
								submitHandler(fieldName, { [fieldName]: fieldValue })
							})}
						>
							{loading ? <Spinner /> : <i className="fa fa-check"></i>}
						</button>
					</div>
					{errors?.[fieldName] && (
						<span className="error-text text-danger">
							{errors?.[fieldName]?.message}
						</span>
					)}
				</>
			) : (
				<>
					<h2 className="fw-bold mb-0 cursor-pointer on-hvr-primary show-input-edit-title-btn fs-2 text-wrap-oneline">
						{fieldName !== 'password' && fieldValue + utmParams}
						{fieldName === 'password' ? 'Edit password' : null}
					</h2>
					{fieldName === 'destinationUrl' && notUpgradedEditDestinationUrl ? (
						<i className="fas ms-2 fa-lock"></i>
					) : (
						<span
							className="btn btn-icon btn-circle btn-active-color-info w-25px h-25px bg-body ms-2 show-input-edit-title-btn"
							onClick={() => editModeHandler(fieldName, true)}
						>
							<i className="fa fa-pencil text-gray-700" />
						</span>
					)}
				</>
			)}
		</div>
	)
}

export default InputField
