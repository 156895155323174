import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../wrapper/pageWrapper'
import CreateQrHeader from './header'
import FinelResultedQr from './finelResultedQr'
import { Card, CardBody, Col, Row, TabContent } from 'react-bootstrap'
import AccordionComponent from './qr-code-forms/businessCardForm'
import QrShower from './qrShower'
import CustomQr from './customQr'
import Tabs from './tabs'
import QrLink from './QrLink'
import QrCodesAPI from '../../../services/http/qrCodes'
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import { useNavigate } from 'react-router-dom'
import Vcard from './qr-code-forms/businessPage'
import { generateRandomString } from '../../../utils/generateRandomString'
import BusinessPageQRForm from './qr-code-forms/businessPage'
import BarcodeForm from './qr-code-forms/barcodeForm'
import WebsiteForm from './qr-code-forms/websiteForm'
import { fileUpload } from '../../../services/http/fileUpload'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap'
import PdfForm from './qr-code-forms/PdfForm'
import ImagesQr from './qr-code-forms/ImagesQr'
import AudioQr from './qr-code-forms/AudioQr'
import VideoQr from './qr-code-forms/VideoQr'
import { AuthContxt } from '../../../store/authContxt'

const QrGeneration = ({ qrType, isEdit = false, editData }) => {
	const [loading, setLoading] = useState(false)
	const { errors, setErrors } = useContext(AuthContxt)
	const [error, setError] = useState('')
	const [isInvalid, setIsInvalid] = useState(false)
	const [message, setMessage] = useState('')
	const [tab, setTab] = useState(1)
	const [open, setOpen] = useState('0')
	const navigate = useNavigate()
	const BASE_URL = process.env.REACT_APP_QR_BASE_URL

	const tabsHandler = (newTab) => {
		if (newTab !== tab) setTab(newTab)
	}
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		videos: [],
		imageList: [],
		bio: 'doe',
		mobile: '',
		phone: '',
		fax: '',
		email: '',
		qrType: qrType || 'website',
		company: '',
		job: '',
		address: '',
		website: '',
		summary: '',
		businessType: '',
		facebook: '',
		instagram: '',
		google: '',
		linkedin: '',
		welcomeImage: '',
		profilePic: '',
		title: 'Preview',
		qr: '',
		qrLogoId: process.env.REACT_APP_QR_LOGO_ID || '',
		destinationUrl: 'https://divsly.com',
		slashTag: '',
		brandedDomain: BASE_URL,
		preset: '#000000',
		color: '#000000',
		bgColor: '#ffffff',
		isBgTransparent: false,
		pattern: 'classy',
		corner: 'square',
		logo: '',
		edit: 0,
		frame: null,
		gradientColor: '',
		primaryColor: '#f1416c',
		audioPrimary: '#000000',
		primary: '',
		secondary: '',
		textColor: '',
		text: 'Scan Me',
		about: '',
		aboutName: '',
		aboutPhone: '',
		aboutEmail: '',
		aboutWebsite: '',
		mondayOpen: 'monday',
		tuesdayOpen: 'tuesday',
		wednesdayOpen: 'wednesday',
		thursdayOpen: 'thursday',
		fridayOpen: 'friday',
		saturdayOpen: 'saturday',
		sundayOpen: 'sunday',
		mondayStart: '09:00',
		tuesdayStart: '09:00',
		wednesdayStart: '09:00',
		thursdayStart: '09:00',
		fridayStart: '09:00',
		saturdayStart: '09:00',
		sundayStart: '09:00',
		mondayEnd: '20:00',
		tuesdayEnd: '20:00',
		wednesdayEnd: '20:00',
		thursdayEnd: '20:00',
		fridayEnd: '20:00',
		saturdayEnd: '20:00',
		sundayEnd: '20:00',
		ctaColor: '#e9e7e7',
		buttonText: 'View',
		buttonLink: 'https://www.divsly.com',
		audioTitle: '',
		bannerImage: '',
		pdfPassword: '',
	})

	const editQrCode = async (template) => {
		const auth_token = localStorage.getItem('auth_token')
		QrCodesAPI.updateQrCodeCustomization(auth_token, formData?.id, template)
			.then((response) => {
				navigate('/qr-codes')
			})
			.catch((error) => {
				messageHandler(
					error?.response?.data?.data?.message ||
						error?.response?.data?.message ||
						'Something went wrong'
				)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleSubmit = async () => {
		setLoading(true)

		const auth_token = localStorage.getItem('auth_token')
		let finalTemp = {
			qrType: qrType || '',
		}
		let fileUrl
		if (qrType === 'pdf' && !(formData?.file || formData?.pdf)) {
			// setError({pdf: "Need a PDF file"});
			messageHandler('Need a PDF file')
			setTimeout(() => {
				setLoading(false)
			}, 1000)
			return
		}
		if (qrType === 'business' && !formData?.headline) {
			setErrors({ headline: 'Please add headline' })
			setOpen('2')
			setTimeout(() => {
				setLoading(false)
				setOpen(false)
			}, 1000)
			return
		}

		if (qrType === 'business' && !formData?.title) {
			setErrors({ title: 'Please add title' })
			setTimeout(() => {
				setLoading(false)
			}, 1000)
			return
		}

		if (formData?.file) {
			fileUrl = await fileUpdater(formData.file, auth_token)
		}
		let banner = ''
		if (formData?.profilefile) {
			banner = await fileUpdater(formData.profilefile, auth_token)
		}

		let data
		if (qrType === 'business') {
			data = {
				primaryColor: formData?.primaryColor || '',
				gradientColor: formData?.gradientColor || '',
				company: formData?.company || '',
				headline: formData?.headline || '',
				summary: formData?.summary || '',
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				buttonColor: formData?.buttonColor || '',
				street: formData?.street || '',
				image: fileUrl || formData?.image,
				address: formData?.city || '',
				location: encodeURIComponent(formData?.location) || '',
				postalCode: formData?.postalCode || '',
				country: formData?.country || '',
				city: formData?.city || '',
				title: formData?.title || '',
				about: formData?.about || '',
				aboutName: formData?.aboutName || '',
				aboutPhone: formData?.aboutPhone || '',
				aboutEmail: formData?.aboutEmail || '',
				aboutWebsite: formData?.aboutWebsite || '',
			}
			;[
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday',
			]?.forEach((e) => {
				data[`${e.toLowerCase()}Open`] = formData?.[`${e.toLowerCase()}Open`]
				data[`${e.toLowerCase()}Start`] = formData?.[`${e.toLowerCase()}Start`]
				data[`${e.toLowerCase()}End`] = formData?.[`${e.toLowerCase()}End`]
			})
		} else if (qrType === 'vcard') {
			data = {
				primaryColor: formData?.primaryColor || '',
				gradientColor: formData?.gradientColor || '',
				firstName: formData?.firstName || '', // If formData.firstName exists, use it, otherwise an empty string
				lastName: formData?.lastName || '', // Add other formData fields if needed
				mobile: formData?.mobile || '',
				phone: formData?.phone || '',
				fax: formData?.fax || '',
				email: formData?.email || '',
				company: formData?.company || '',
				job: formData?.job || '',
				address: formData?.address || '',
				website: formData?.website || '',
				summary: formData?.summary || '',
				businessType: formData?.businessType || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				welcomeImage: formData?.welcomeImage || '',
				image: fileUrl || formData?.image || '',
				ctaColor: formData?.ctaColor,
				location: encodeURIComponent(formData?.location) || '',
				vcard: createVCardData(),
			}
		} else if (qrType === 'barcode') {
			data = {
				globalTradeItemNumber: formData?.globalTradeItemNumber,
			}
		} else if (qrType === 'pdf') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				pdf: fileUrl || formData?.pdf || '',
			}
		} else if (qrType === 'audio') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				audio: fileUrl || formData?.audio || '',
				banner: banner || formData?.banner || '',
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				audioTitle: formData?.audioTitle,
			}
		} else if (qrType === 'video') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				audio: fileUrl || '',
				image: formData?.profilePic,
				videos: formData?.videos,
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
				pageTitle: formData?.pageTitle,
			}
		} else if (qrType === 'images') {
			data = {
				primaryColor: formData?.primaryColor || '',
				ctaColor: formData?.ctaColor || '',
				// gradientColor: formData?.gradientColor || "",
				// firstName: formData?.firstName || "", // If formData.firstName exists, use it, otherwise an empty string
				// lastName: formData?.lastName || "", // Add other formData fields if needed
				company: formData?.company || '',
				website: formData?.website || '',
				description: formData?.description || '',
				facebook: formData?.facebook || '',
				instagram: formData?.instagram || '',
				google: formData?.google || '',
				linkedin: formData?.linkedin || '',
				imageList: formData?.imageList,
				buttonText: formData?.buttonText || '',
				buttonUrl: formData?.buttonUrl || '',
			}
		}
		finalTemp = {
			...finalTemp,
			...formData,
			fieldData: JSON.stringify(data),
		}
		console.log(finalTemp)
		if (qrType !== 'website' && qrType !== 'barcode') {
			finalTemp.destinationUrl = ''
		}
		if (isEdit) {
			await editQrCode({
				...finalTemp,
				frame: finalTemp?.frame?.type,
				bgColor: finalTemp?.isBgTransparent ? '#ffffff' : finalTemp?.bgColor,
				brandedDomain: finalTemp?.brandedDomain,
				slashTag: finalTemp?.slashTag,
			})
			return
		}
		let newExpirationDate = finalTemp.expirationDate || ''
		if (newExpirationDate === '') {
			const currentDate = new Date()
			const futureDate = new Date(currentDate)
			futureDate.setDate(currentDate.getDate() + 90)
			newExpirationDate = futureDate
		}
		if (finalTemp.slashTag === '') {
			setError('slashTag', {
				type: 'custom',
				message: 'Custom slug is required.',
			})
		}
		QrCodesAPI.createQrCode(auth_token, {
			...formData,
			...finalTemp,
			frame: finalTemp?.frame?.type || '',
			slashTag: convertToURLFriendly(formData?.slashTag),
			bgColor: finalTemp?.isBgTransparent ? '#ffffff' : finalTemp?.bgColor,
			expirationDate: newExpirationDate,
		})
			.then((response) => {
				navigate('/qr-codes')
			})
			.catch((error) => {
				messageHandler(
					error?.response?.data?.data?.message ||
						error?.response?.data?.message ||
						'Something went wrong'
				)
			})
			.finally(() => {
				setLoading(false)
				setOpen('0')
			})
	}

	// Function to create vCard data from formData
	const createVCardData = () => {
		const vCardData = `
        BEGIN:VCARD
        VERSION:3.0
        FN:${formData?.firstName || ''} ${formData?.lastName || ''}
        N:${formData?.lastName || ''};${formData?.firstName || ''};;
        EMAIL:${formData?.email || ''}
        TEL;TYPE=CELL:${formData?.mobile || ''}
        TEL;TYPE=WORK,VOICE:${formData?.phone || ''}
        ADR;TYPE=WORK:;;${formData?.address || ''}
        ORG:${formData?.company || ''}
        TITLE:${formData?.job || ''}
        URL:${formData?.website || ''}
        NOTE:${formData?.summary || ''}
        X-SOCIALPROFILE;type=facebook:${formData?.facebook || ''}
        X-SOCIALPROFILE;type=instagram:${formData?.instagram || ''}
        X-SOCIALPROFILE;type=google:${formData?.google || ''}
        X-SOCIALPROFILE;type=linkedin:${formData?.linkedin || ''}
        END:VCARD
      `.trim() // Trim to remove excess spaces or new lines

		return vCardData
	}

	const messageHandler = (msg) => {
		setMessage(msg)
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}
	const fileUpdater = async (file, auth_token) => {
		let fData = new FormData()
		fData.append('file', file)

		try {
			const resp = await fileUpload('/qr-code/file-upload', fData, auth_token)
			console.log(resp)
			return resp.data.url
		} catch (error) {
			console.error('Error uploading file:', error)
		}
	}
	const multiFileUpdater = async (files, auth_token) => {
		let fData = new FormData()
		Array.from(files)?.forEach((file, index) => {
			fData?.append('files', file)
			// let count =0
			// setUploadPercentage&& setInterval(() => {
			//   setUploadPercentage((prev)=>(prev+ index* files.length))
			// }, 1000);
		})
		console.log('fData: ', fData)
		try {
			const resp = await fileUpload('/qr-code/files-upload', fData, auth_token)
			console.log('multiFile api res: ', resp)
			return resp.data.files || []
		} catch (error) {
			console.error('Error uploading files:', error)
		}
	}

	useEffect(() => {
		if (isEdit && editData?.qrType) {
			if (editData?.qrType !== 'website') {
				const fieldData = JSON.parse(editData?.fieldData || '') || {}
				setFormData((prev) => ({
					...prev,
					...editData,
					...fieldData,
					location: decodeURIComponent(fieldData?.location),
				}))
			} else {
				setFormData((prev) => ({ ...prev, ...editData }))
			}
		}
	}, [editData])
	useEffect(() => {
		const tag = Math.random().toString(36).substr(2, 5)
		setFormData((prev) => ({ ...prev, slashTag: tag }))
	}, [])
	return (
		<PageWrapper>
			<div className="h-100">
				<Card className="bg-white border-0 rounded-lg-3">
					<CreateQrHeader
						loader={loading}
						isEdit={isEdit}
						handleSubmit={handleSubmit}
						isInvalid={isInvalid}
					/>
					<div className="d-flex justify-content-end">
						{message ? (
							<p className="error-text text-danger mb-3">{message}</p>
						) : null}
					</div>
					<CardBody className="card-body px-6 px-lg-8 pt-0 overflow-x-auto">
						<Row>
							<Col md={7} className="p-lg-5 pe-lg-2 px-3 pt-0 pt-lg-0 col-12">
								<div className="card card-flush mw-1000px border-0 ps-0 pe-lg-8 h-xl-100">
									<Tabs handler={tabsHandler} tab={tab} />
									{tab === 1 ? (
										<div>
											{(qrType === 'website' || qrType === 'barcode') && (
												<div style={{ marginTop: '18px' }}>
													<QrLink
														formData={formData}
														setFormData={setFormData}
														isEdit={isEdit}
														qrType={qrType}
													/>
												</div>
											)}
											{qrType === 'business' ? (
												<BusinessPageQRForm
													formData={formData}
													tab={open}
													isEdit={isEdit}
													qrType={qrType}
													setFormData={setFormData}
												/>
											) : qrType === 'barcode' ? (
												<BarcodeForm
													formData={formData}
													setFormData={setFormData}
												/>
											) : qrType === 'website' ? (
												<WebsiteForm
													formData={formData}
													isEdit={isEdit}
													setFormData={setFormData}
												/>
											) : qrType === 'pdf' ? (
												<PdfForm
													formData={formData}
													setFormData={setFormData}
												/>
											) : qrType === 'images' ? (
												<ImagesQr
													fileUpdater={multiFileUpdater}
													formData={formData}
													setFormData={setFormData}
												/>
											) : qrType === 'audio' ? (
												<AudioQr
													formData={formData}
													setFormData={setFormData}
													qrType={qrType}
												/>
											) : qrType === 'video' ? (
												<VideoQr
													formData={formData}
													setFormData={setFormData}
												/>
											) : (
												qrType === 'vcard' && (
													<AccordionComponent
														formData={formData}
														isEdit={isEdit}
														qrType={qrType}
														setFormData={setFormData}
														setIsInvalid={setIsInvalid}
													/>
												)
											)}
										</div>
									) : (
										<CustomQr formData={formData} setFormData={setFormData} />
									)}
								</div>
							</Col>
							<QrShower
								qrType={qrType}
								formData={formData}
								tabsHandler={tabsHandler}
								setFormData={setFormData}
							/>
						</Row>
					</CardBody>
				</Card>
			</div>
		</PageWrapper>
	)
}

export default QrGeneration
