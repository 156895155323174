import React, { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import qrpdfpreview from '../../../../assets/qr-preview/qr-pdf-preview.png'
import { isColorDark } from './color-checker/isColorDark'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const PdfQrPreview = ({ formData }) => {
	const [pageNumber, setPageNumber] = useState(1)
	const [numPages, setNumPages] = useState(null)
	const [showPreview, setShowPreview] = useState(false)
	const [containerWidth, setContainerWidth] = useState(0)
	const previewRef = useRef(null)
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
	}

	// Use ResizeObserver to detect changes in the container size
	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			if (previewRef.current) {
				setContainerWidth(previewRef.current.offsetWidth)
			}
		})

		if (previewRef.current) {
			resizeObserver.observe(previewRef.current)
			// Set the initial width
			setContainerWidth(previewRef.current.offsetWidth)
		}

		return () => {
			if (previewRef.current) {
				resizeObserver.unobserve(previewRef.current)
			}
		}
	}, [])

	const nextPage = () => {
		if (pageNumber < numPages) {
			setPageNumber(pageNumber + 1)
		}
	}

	const prevPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1)
		}
	}

	return (
		<div className="pdf-qr-preview-container h-100">
			<div>
				<div className="pdf-qr-head">
					<p className="fs-5 mb-1 text-gray-200">
						{formData?.company || 'Divsly'}
					</p>
					<h1 className="fw-bold fs-1">{formData?.title || 'Important PDF'}</h1>
					<p className="fs-5 text-gray-200">
						{formData?.description || 'Here is description for pdf.'}
					</p>
				</div>
			</div>
			<div className="pdf-qr-body">
				<div
					className="pdf-qr-backdrop-clr"
					style={{
						background: formData?.gradientColor
							? `linear-gradient(to top, ${formData?.primaryColor}, ${formData?.gradientColor})`
							: formData?.primaryColor,
					}}
				></div>
			</div>
			<p className="text-center pt-5">{formData?.websiteUrl}</p>

			{formData?.pdf ? (
				<div
					className="pdf-qr-preview"
					ref={previewRef}
					style={{
						width: '90%',
						maxWidth: '400px',
						height: 'auto',
						border: '1px solid #ccc',
						overflow: 'hidden',
					}}
				>
					<Document
						file={{ url: formData?.pdf, password: formData?.pdfPassword }}
						onLoadError={(error) =>
							console.error('Error while loading document', error)
						}
						onLoadSuccess={onDocumentLoadSuccess}
						onPassword={() => {}}
					>
						<Page pageNumber={1} width={containerWidth || 275} />
					</Document>
					<div className="pdf-navigation d-none">
						<button onClick={prevPage} disabled={pageNumber <= 1}>
							Previous
						</button>
						<p>
							Page {pageNumber} of {numPages}
						</p>
						<button onClick={nextPage} disabled={pageNumber >= numPages}>
							Next
						</button>
					</div>
				</div>
			) : (
				<img className="pdf-qr-preview" src={qrpdfpreview} alt="QR Preview" />
			)}
			<button
				className={`preview-pdf-sticky display-block btn rounded-0 text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'}`}
				style={{ background: formData?.ctaColor }}
				onClick={() => {
					if (formData?.pdf) {
						window.open(formData?.pdf, '_blank')
					} else {
						console.log('PDF URL is missing')
					}
				}}
			>
				<i
					className={`fa-solid fa-up-right-from-square text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'}`}
				></i>
				Preview PDF
			</button>
		</div>
	)
}

export default PdfQrPreview
