import { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FormGroup, Input } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ProfileInfo from './profileInfo'
import { AuthContxt } from '../../store/authContxt'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { EMAIL_REGEX, PHONE_REGEX, USER_TYPE } from '../../utils/enum'
import UserSettingsApi from '../../services/http/userSettings'

const ProfileDetails = ({ profileDetailsHandler, stripeCustomer }) => {
	const [detailForm, setDetailForm] = useState(false)
	const [phoneRegex, setPhoneRegex] = useState('')
	const [companyField, setCompnayField] = useState(false)
	const [vatIdField, setVatIdField] = useState(false)
	const { profile } = useContext(AuthContxt)

	const newUserValidation = yup.object().shape({
		firstName: yup.string().required('Please fill this field!'),
		lastName: yup.string(),
		email: yup
			.string()
			.required('Please fill this field!')
			.matches(EMAIL_REGEX, 'Please enter a valid email'),
		mobile: yup
			.string()
			.required()
			.matches(phoneRegex, `Please enter valid phone no.`),
	})

	const form = useForm({
		resolver: yupResolver(newUserValidation),
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			mobile: '',
			company: '',
			vatId: '',
			country: '',
		},
	})
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = form

	const submitHandler = async (payload) => {
		if (payload) {
			try {
				const { data } = await UserSettingsApi.updateProfile({
					...payload,
				})
				profileDetailsHandler({ ...payload, ...data?.data })
				setDetailForm(false)
			} catch (e) {
				console.log('Error while saving profile details: ', e)
			}
		}
	}

	useEffect(() => {
		try {
			setValue('firstName', profile?.firstName)
			setValue('lastName', profile?.lastName)
			setValue('mobile', profile?.mobile)
			setValue('email', profile?.email)
			setValue('company', profile?.company)
			setValue('vatId', profile?.vatId)
			setValue('country', profile?.country)

			if (stripeCustomer) {
				const data = stripeCustomer
				if (data?.metadata?.vatId) {
					setValue('vatId', data?.metadata?.vatId)
					setVatIdField(true)
				}
				if (data?.metadata?.company) {
					setValue('company', data?.metadata?.company)
					setCompnayField(true)
				}
			}
		} catch (e) {
			console.log('Error while fetching customers: ', e)
		}
	}, [stripeCustomer, setValue, profile])

	const disableProfileEdit =
		profile?.userType === USER_TYPE.USER ||
		profile?.userType === USER_TYPE.ADMIN

	return (
		<div class="mb-5 mt-lg-5 mt-6">
			<div className="card-header min-h-lg-70px min-h-50px p-0 mb-5 cursor-pointer">
				<div className="card-title m-0">
					<h3 className="fw-bold m-0">Profile Details</h3>
				</div>

				<button
					disabled={disableProfileEdit}
					className={
						detailForm
							? 'd-none'
							: 'btn btn-sm btn-info rounded-1 align-self-center d-block'
					}
					onClick={() => !disableProfileEdit && setDetailForm(true)}
				>
					Edit Profile
				</button>
			</div>
			{!detailForm ? (
				<ProfileInfo values={watch()} />
			) : (
				<form onSubmit={handleSubmit(submitHandler)}>
					<div class="collapse show">
						<div class="card-body p-0 pt-lg-5">
							<div class="row mb-lg-6">
								<label class="col-lg-4 col-form-label required fw-semibold fs-6">
									Full Name
								</label>

								<div class="col-lg-8">
									<div class="row">
										<div class="col-lg-6 fv-row">
											<FormGroup>
												<Controller
													name={'firstName'}
													control={control}
													render={({ field: { onBlur, value, onChange } }) => (
														<Input
															value={value}
															type="text"
															className="form-control form-control-lg border rounded-1 h-50px border-gray-250 mb-3 mb-lg-0"
															placeholder="First name"
															onChange={onChange}
															onBlur={onBlur}
														/>
													)}
												/>
											</FormGroup>
											{errors.firstName ? (
												<div style={{ textAlign: 'end' }}>
													<span className="error-text text-danger">
														{errors.firstName.message}
													</span>
												</div>
											) : null}
										</div>

										<div class="col-lg-6 fv-row">
											<FormGroup>
												<Controller
													name={'lastName'}
													control={control}
													render={({ field: { onBlur, value, onChange } }) => (
														<Input
															value={value}
															type="text"
															className="form-control form-control-lg border rounded-1 h-50px border-gray-250 mb-3 mb-lg-0"
															placeholder="Last name"
															onChange={onChange}
															onBlur={onBlur}
														/>
													)}
												/>
											</FormGroup>
											{errors.lastName ? (
												<div style={{ textAlign: 'end' }}>
													<span className="error-text text-danger">
														{errors.lastName.message}
													</span>
												</div>
											) : null}
										</div>
									</div>
								</div>
							</div>

							<div class="row mb-lg-6">
								<label class="col-lg-4 col-form-label required fw-semibold fs-6">
									Email
								</label>

								<div class="col-lg-8 fv-row">
									<FormGroup>
										<Controller
											name={'email'}
											control={control}
											render={({ field: { onBlur, value, onChange } }) => (
												<Input
													value={value}
													type="email"
													className="form-control form-control-lg border rounded-1 h-50px border-gray-250 mb-3 mb-lg-0"
													placeholder="Email"
													// onChange={onChange}
													readOnly
													onBlur={onBlur}
												/>
											)}
										/>
									</FormGroup>
									{errors.email ? (
										<div style={{ textAlign: 'end' }}>
											<span className="error-text text-danger">
												{errors.email.message}
											</span>
										</div>
									) : null}
								</div>
							</div>

							<div class="row mb-lg-6">
								<label class="col-lg-4 col-form-label fw-semibold fs-6">
									<span class="">Contact Phone</span>
									<span class="ms-1">
										<i class="ki-duotone ki-information-5 text-gray-500 fs-6">
											<span class="path1"></span>
											<span class="path2"></span>
											<span class="path3"></span>
										</i>
									</span>{' '}
								</label>
								<div class="col-lg-8 fv-row">
									<FormGroup>
										<Controller
											name={'mobile'}
											control={control}
											render={({ field: { onBlur, value } }) => (
												<PhoneInput
													onChange={(phone, data, event, formattedValue) => {
														const counrtyCode = data?.countryCode?.toUpperCase()
														const countryPhoneRegex = PHONE_REGEX[counrtyCode]
														console.log(countryPhoneRegex, 'countryPhoneRegex')
														console.log(data, 'countryPhoneRegexdata', phone)
														setPhoneRegex(countryPhoneRegex)
														setValue('mobile', phone)
														setValue('country', data?.countryCode)
													}}
													country={profile?.country?.toLowerCase()}
													onBlur={onBlur}
													value={value}
													name="mobile"
													className="form-control form-control-lg border rounded-1 h-50px border-gray-250 mb-3 mb-lg-0"
													placeholder="Mobile"
													disableDropdown={true}
													disableCountryGuess={true}
												/>
											)}
										/>
									</FormGroup>
									{errors.mobile ? (
										<div style={{ textAlign: 'end' }}>
											<span className="error-text text-danger">
												{errors.mobile.message}
											</span>
										</div>
									) : null}
								</div>
							</div>

							<div class="row mb-0 mt-lg-10">
								<label class="col-lg-4 col-form-label fw-semibold fs-6">
									Company name
								</label>

								<div class="col-lg-8 d-flex flex-column">
									<div class="form-check form-check-solid form-check-info form-switch form-check-custom fv-row">
										<input
											class="form-check-input w-45px h-30px"
											type="checkbox"
											checked={companyField}
											onClick={() => setCompnayField(!companyField)}
											readOnly
										/>
										<label
											class="form-check-label"
											for="allowmarketing"
										></label>
										<span class="fs-6 ms-3">
											Include my company name on my invoice
										</span>
									</div>
									<div
										style={{ display: companyField ? 'block' : 'none' }}
										class="mt-5 mb-lg-10"
									>
										<FormGroup>
											<Controller
												name={'company'}
												control={control}
												render={({ field: { onBlur, value, onChange } }) => (
													<Input
														value={value}
														type="text"
														className="form-control h-50px border-gray-250 border rounded-1 mt-2"
														placeholder="Enter Company Name"
														onChange={onChange}
														onBlur={onBlur}
													/>
												)}
											/>
										</FormGroup>
									</div>
								</div>
							</div>

							<div class="row mb-0">
								<label class="col-lg-4 col-form-label fw-semibold fs-6">
									VAT ID
								</label>

								<div class="col-lg-8 d-flex flex-column">
									<div class="form-check form-check-solid form-check-info form-switch form-check-custom fv-row">
										<input
											class="form-check-input w-45px h-30px"
											type="checkbox"
											checked={vatIdField}
											onClick={() => setVatIdField(!vatIdField)}
											readOnly
										/>
										<label class="form-check-label"></label>
										<span class="fs-6 ms-3">
											Include my VAT ID on my invoice
										</span>
									</div>
									<div
										style={{ display: vatIdField ? 'block' : 'none' }}
										class="mt-5 mb-5"
									>
										<FormGroup>
											<Controller
												name={'vatId'}
												control={control}
												render={({ field: { onBlur, value, onChange } }) => (
													<Input
														value={value}
														type="text"
														className="form-control h-50px border-gray-250 border rounded-1 mt-2"
														placeholder="Enter VAT ID"
														onChange={onChange}
														onBlur={onBlur}
													/>
												)}
											/>
										</FormGroup>
									</div>
								</div>
							</div>
						</div>

						<div class="card-footer d-flex justify-content-end py-6 px-0">
							<button
								type="button"
								class="btn btn-light w-lg-150px rounded-1 me-4"
								onClick={() => setDetailForm(false)}
							>
								Cancel
							</button>
							<button type="submit" class="btn btn-info w-lg-150px rounded-1">
								Save
							</button>
						</div>
					</div>
				</form>
			)}
		</div>
	)
}

export default ProfileDetails
