import React, { useState, useRef } from 'react'
import { Document, Page } from 'react-pdf'
import PopupModal from '../../../../common/popupModal'
import { Input } from 'reactstrap'

const FileUpload = ({ setFormData, formData, fileUpdater, qrType }) => {
	const [file, setFile] = useState(null)
	const [imageList, setImageList] = useState([])
	const [uploadPercentage, setUploadPercentage] = useState(0)
	const [uploadStatus, setUploadStatus] = useState('')
	const [hideLoadBar, setHideLoadBar] = useState(true)
	const dropAreaRef = useRef(null)
	const fileInputRef = useRef(null)
	const [passwordModalOpen, setPasswordModalOpen] = useState(false)
	const [password, setPassword] = useState('')
	const [fileError, setFileError] = useState(null)

	const handlePasswordSubmit = () => {
		setFileError(null)
		setPasswordModalOpen(false)
		setFormData((prev) => ({
			...prev,
			pdfPassword: password,
		}))
	}

	const onPassword = (callback, reason) => {
		if (reason === 1) {
			// Required password
			setPasswordModalOpen(true)
		} else {
			// Incorrect password
			setFileError('Incorrect password. Please try again.')
			setPasswordModalOpen(true)
		}
	}

	const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20MB in bytes

	// Handle file selection manually or via drag-and-drop
	const handleFileChange = async (e) => {
		setFormData((prev) => ({
			...prev,
			pdfPassword: '',
		}))
		setPassword('')
		const selectedFile =
			qrType === 'images' ? e.target.files : e.target.files[0]
		await validateFile(selectedFile)
	}

	// Handle file drop
	const handleFileDrop = (e) => {
		e.preventDefault()
		const selectedFile = e.dataTransfer.files[0]
		validateFile(selectedFile)
		dropAreaRef.current.style.border = '2px dashed #ccc'
	}

	const validateFile = async (selectedFile) => {
		if (!selectedFile) return

		// Check file size
		if (
			selectedFile.size > MAX_FILE_SIZE || // for pdf and audio
			selectedFile[0]?.size > MAX_FILE_SIZE // for images
		) {
			setUploadStatus(
				'File size exceeds the 20MB limit. Please select a smaller file.'
			)
			setHideLoadBar(true)
			return
		} else {
			setUploadStatus('File upload successful')
		}

		setHideLoadBar(false)
		setUploadPercentage(0)
		setFile(selectedFile)

		const auth_token = localStorage.getItem('auth_token')
		const startTime = Date.now()
		let percentage = 0

		const intervalId = setInterval(() => {
			const elapsed = Date.now() - startTime
			percentage = Math.min(
				(elapsed / (10000 * selectedFile?.length)) * 100,
				100
			)
			setUploadPercentage(percentage)
		}, 100)

		let newImageList = []
		if (qrType === 'images') {
			newImageList = await fileUpdater(selectedFile, auth_token)
		}

		clearInterval(intervalId)
		setUploadPercentage(100)

		setTimeout(() => {
			setHideLoadBar(true)
		}, 1000)

		setImageList(newImageList)
		setFormData((prev) => ({
			...prev,
			...(qrType === 'images'
				? { imageList: newImageList?.length > 0 ? [...newImageList] : [] }
				: { file: selectedFile, [qrType]: URL.createObjectURL(selectedFile) }),
		}))
	}

	// Dragging events to style drop area
	const handleDragOver = (e) => {
		e.preventDefault()
		dropAreaRef.current.style.border = '2px solid #4caf50'
	}

	const handleDragLeave = (e) => {
		e.preventDefault()
		dropAreaRef.current.style.border = '2px dashed #ccc'
	}

	// Trigger file input click
	const handleDropAreaClick = () => {
		fileInputRef.current.click()
	}

	return (
		<div>
			<div
				ref={dropAreaRef}
				onClick={handleDropAreaClick}
				onDrop={handleFileDrop}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				style={{
					width: '100%',
					height: '150px',
					border: '2px dashed #ccc',
					borderRadius: '5px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
					marginBottom: '10px',
				}}
			>
				{file && (formData?.pdf || formData?.audio) ? (
					<p>{file.name}</p>
				) : (
					<p>Drag & drop file here, or click to select</p>
				)}

				<input
					ref={fileInputRef}
					type="file"
					accept={
						qrType === 'pdf'
							? '.pdf'
							: qrType === 'audio'
								? 'audio/*'
								: qrType === 'images'
									? 'image/jpeg, image/png'
									: ''
					}
					multiple={qrType === 'images'}
					onChange={handleFileChange}
					style={{ display: 'none' }}
				/>
			</div>

			{file && qrType !== 'pdf' && qrType !== 'audio' && !hideLoadBar && (
				<div style={{ marginTop: '10px' }}>
					<div
						style={{
							width: '100%',
							backgroundColor: '#f0f0f0',
							borderRadius: '5px',
							overflow: 'hidden',
						}}
					>
						<div
							style={{
								width: `${uploadPercentage}%`,
								height: '10px',
								backgroundColor: '#4caf50',
								borderRadius: '5px',
								transition: 'width 0.5s ease',
							}}
						></div>
					</div>
					<p>{Math.ceil(uploadPercentage)}%</p>
				</div>
			)}

			{formData?.audio && (
				<div className="position-relative border border-dark border-opacity-25 rounded-1">
					<i
						onClick={() => {
							setFormData((prev) => ({ ...prev, audio: '', file: null }))
							setFile(null)
						}}
						style={{
							top: '50%',
							right: '10px',
							transform: 'translateY(-50%)',
						}}
						className="fa-solid fa-circle-xmark fs-3 text-danger position-absolute"
					></i>
					<div style={{ padding: '10px' }}>{formData?.file?.name}</div>
				</div>
			)}

			{formData?.pdf && (
				<>
					<div className="position-relative" style={{ width: 'fit-content' }}>
						<i
							onClick={() => {
								setFormData((prev) => ({ ...prev, pdf: '', file: null }))
								setFile(null)
							}}
							style={{
								height: '32px',
								top: '10px',
								right: '10px',
								zIndex: '5',
							}}
							className="fa-solid fa-circle-xmark fs-3 text-danger position-absolute"
						></i>
						<div
							className="pdf-qr-preview m-0"
							style={{ width: 'fit-content' }}
						>
							<Document
								file={{ url: formData?.pdf, password: formData?.pdfPassword }}
								onLoadError={(error) =>
									console.error('Error while loading document', error)
								}
								onPassword={onPassword}
							>
								<Page pageNumber={1} height={100} width={300} />
							</Document>
						</div>
					</div>
					<PopupModal
						isOpen={passwordModalOpen}
						onClose={() => setPasswordModalOpen(false)}
						heading={'Password protection enabled'}
						showFirstButton={false}
						btn2_text={'Submit'}
						onSecondButtonClick={handlePasswordSubmit}
					>
						<Input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="*****"
						/>
						{fileError && (
							<p className="error-text text-danger mt-2 mb-2">{fileError}</p>
						)}
					</PopupModal>

					{fileError && <p className="error-text">{fileError}</p>}
				</>
			)}

			{uploadStatus && <p>{uploadStatus}</p>}
		</div>
	)
}

export default FileUpload
