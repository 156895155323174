const createUrlWithParams = (destinationUrl, params = {}) => {
	const url = new URL(destinationUrl)

	// List of optional parameters with default values
	const optionalParams = {
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
		utm_id: '',
		utm_term: '',
		utm_content: '',
		...params, // Override defaults with provided params
	}

	// Append each parameter to the URL if it has a value
	for (const [key, value] of Object.entries(optionalParams)) {
		if (value) {
			url.searchParams.set(key, value)
		}
	}

	return url.toString()
}

export default createUrlWithParams
