import { useContext, useState } from 'react'
import DetailForm from './form'
import Info from './info'
import { AuthContxt } from '../../../../store/authContxt'
import { USER_TYPE } from '../../../../utils/enum'
import { UserSettingsContxt } from '../../userSettingContxt'

const ProfileDetails = () => {
	const { profile } = useContext(AuthContxt)
	const { edit, setEdit } = useContext(UserSettingsContxt)
	// const [detailForm, setDetailForm] = useState(false);

	const detailFormHandler = () => {
		setEdit(false)
	}

	const disableProfileEdit =
		profile?.userType === USER_TYPE.USER ||
		profile?.userType === USER_TYPE.ADMIN

	return (
		<div
			className="card mb-5 mb-xl-10"
			style={{ display: 'block' }}
			id="profileview"
		>
			<div className="card-header min-h-lg-70px min-h-50px px-5 px-lg-10 py-5 cursor-pointer">
				<div className="card-title m-0">
					<h3 className="fw-bold m-0">Profile Details</h3>
				</div>

				<button
					disabled={disableProfileEdit}
					className={
						edit
							? 'd-none'
							: 'btn btn-sm btn-info rounded-1 align-self-center d-block'
					}
					onClick={() => !disableProfileEdit && setEdit(true)}
				>
					Edit Profile
				</button>
			</div>

			<div className="card-body p-lg-9 p-5 pb-0">
				{edit ? (
					<DetailForm
						edittedProfile={edit ? profile : null}
						detailFormHandler={detailFormHandler}
					/>
				) : (
					<Info profile={profile} />
				)}
			</div>
		</div>
	)
}

export default ProfileDetails
