import { useContext, useState } from 'react'
import { Spinner, Tooltip } from 'reactstrap'
import { AuthContxt } from '../../../store/authContxt'
import { useLocation, useNavigate } from 'react-router-dom'

const CreateQrHeader = ({ loader, isEdit, handleSubmit, isInvalid }) => {
	const { quota, loading } = useContext(AuthContxt)
	const isLimitReached = (quota?.qrCode === 0 && !isEdit) || false

	const [tooltipOpen, setTooltipOpen] = useState(false)
	const location = useLocation() // Get current URL location
	const navigate = useNavigate() // Hook for navigation

	// Define a mapping of URLs to corresponding titles
	const pageTitles = {
		'/create-qr-code-website': 'QR Code Website',
		'/create-qr-code-business-page': 'QR Code Business Page',
		'/create-qr-code-vcard': 'QR Code VCard',
		'/create-qr-code-2dbarcode': '2d Barcode',
		'/create-qr-code-video': 'QR Code Videos',
		'/create-qr-code-audio': 'QR Code Audio',
		'/create-qr-code-images': 'QR Code Images',
		'/qr-codes/export': 'Export QR Codes',
		'/qr-codes/import': 'Import QR Codes',
		'/qr-codes/details': 'QR Code Details',
		'/qr-codes/help': 'QR Code Help',
		'/qr-codes/support': 'QR Code Support',
	}

	// Get the current page title based on the URL path
	const pageTitle = pageTitles[location.pathname] || 'QR Codes'

	const toggle = () => {
		if (isLimitReached) {
			setTooltipOpen(!tooltipOpen)
		}
	}

	return (
		<div className="border-bottom px-8 d-flex justify-content-between align-items-center py-lg-8 py-5 w-100">
			<div className="d-flex align-items-start justify-content-start">
				<button
					className="btn bg-none p-0 mt-1 cursor-pointer me-3"
					onClick={() => navigate(-1)} // Go back to the previous page
				>
					<i className="fa-regular fa-arrow-left text-black fs-1"></i>
				</button>
				<h2 className="fs-1 mb-0">{pageTitle}</h2>
			</div>

			<div
				onMouseOver={toggle}
				onMouseLeave={toggle}
				id="qrCodeForm"
				className="card-toolbar d-flex justify-content-center align-items-center"
			>
				<button
					type={isLimitReached ? 'button' : 'submit'}
					onClick={() => {
						handleSubmit && handleSubmit()
					}}
					disabled={loading || loader || isLimitReached || isInvalid}
					className="btn btn-info rounded-1 w-lg-200px"
				>
					{loader ? (
						<span>
							Please wait... <Spinner className="h-15px w-15px" />
						</span>
					) : (
						`${isEdit ? 'Update' : 'Save'} QR Code`
					)}
				</button>
			</div>
			<Tooltip
				placement={'top'}
				isOpen={tooltipOpen}
				target={'qrCodeForm'}
				toggle={toggle}
			>
				{`Your Monthly Qr Code Limit Reached. Please Upgrade your Plan`}
			</Tooltip>
		</div>
	)
}

export default CreateQrHeader
