import { useContext } from 'react'
import { AuthContxt } from '../store/authContxt'

const usePlan = () => {
	const { profile } = useContext(AuthContxt)

	// Check if profile and plan are available
	if (profile && profile.plan) {
		let planType = profile.plan.split('-')[1]

		if (planType === 'starter') {
			return {
				frame: 1,
				preset: 0,
				pattern: 0,
				corner: 0,
				logoToShow: 1,
				plan: 'starter',
				imagesUrl: profile.qrCodes || [],
				newImagesAllowed: 0,
			}
		} else if (planType === 'standard') {
			return {
				frame: 6,
				preset: 3,
				pattern: 3,
				corner: 3,
				logoToShow: 4,
				plan: 'standard',
				imagesUrl: profile.qrCodes || [],
				newImagesAllowed: 1 - profile.qrCodes.length || 0,
			}
		} else if (planType === 'super') {
			return {
				frame: 40,
				preset: 10,
				pattern: 10,
				corner: 10,
				logoToShow: 22,
				plan: 'super',
				imagesUrl: profile.qrCodes || [],
				newImagesAllowed: 2000,
			}
		}
	}

	return {
		frame: 0,
		preset: 0,
		pattern: 0,
		corner: 0,
		logoToShow: 0,
		plan: 'starter',
		imagesUrl: profile.qrCodes || [],
		newImagesAllowed: 0,
	}
}

export default usePlan
