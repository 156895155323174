import { useEffect, useState } from 'react'
import SubscriptionApi from '../../../../services/http/subscription'
import dayjs from 'dayjs'
import { CONTENT_MAPPER } from '../../../subscription/plan'
import { Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'

const FILTER_TABS = [
	{ label: 'Month', value: 'month' },
	{ label: 'Year', value: 'year' },
	{ label: 'All Time', value: 'all' },
]

const BillingHistory = () => {
	const [tab, setTab] = useState('month')
	const [loading, setLoading] = useState(false)
	const [invoiceLoading, setInvoiceLoading] = useState(null)
	const [billingHistory, setBillingHistory] = useState([])

	useEffect(() => {
		const fetchBillingHistory = async () => {
			try {
				setLoading(true)
				const response = await SubscriptionApi.billingHistory({
					duration: tab,
				})
				if (response?.data?.data) {
					setBillingHistory(response?.data?.data)
				}
				setLoading(false)
			} catch (e) {
				console.log('Error whle fetching billing history: ', e)
				setLoading(false)
			}
		}

		fetchBillingHistory()
	}, [tab])

	const downloadURI = (uri) => {
		let link = document.createElement('a')
		link.href = uri
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	const invoiceDownloadHandler = async (invoiceId) => {
		try {
			if (invoiceId !== invoiceLoading) {
				setInvoiceLoading(invoiceId)
				const response = await SubscriptionApi.getInvoice({ invoiceId })
				if (!response?.data?.error) {
					const { invoice_pdf } = response?.data?.data
					invoice_pdf && downloadURI(invoice_pdf)
				} else {
					console.log('Error while generating invoice!')
				}
			}
			setInvoiceLoading(null)
		} catch (e) {
			console.log('Error while getting invoice: ', e)
			setInvoiceLoading(null)
		}
	}
	console.log('Billing History : ', billingHistory)

	return (
		<div className="card ">
			<div className="card-header px-lg-10 px-5 card-header-stretch border-bottom border-gray-200">
				<div className="card-title py-3 ">
					<h3 className="fw-bold m-0">Billing History</h3>
				</div>

				<div className="card-toolbar m-0">
					<ul className="nav nav-stretch nav-line-tabs border-transparent">
						{FILTER_TABS?.map((item) => {
							const { label, value } = item
							return (
								<li key={value} className="nav-item cursor-pointer">
									<span
										className={
											tab === value
												? 'nav-link fs-5 fw-semibold me-3 active'
												: 'nav-link fs-5 fw-semibold me-3'
										}
										onClick={() => setTab(value)}
									>
										{label}
									</span>
								</li>
							)
						})}
					</ul>
				</div>
			</div>

			<div className="tab-content">
				<div className={'card-body p-0 tab-pane fade active show'}>
					<div className="table-responsive">
						<table className="table table-row-bordered align-middle gy-4 gs-lg-9 gs-5">
							<thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
								<tr>
									<td className="min-w-150px ps-lg-10 ps-5">Date</td>
									<td className="min-w-150px">Plan</td>
									<td className="min-w-250px">Description</td>
									<td className="min-w-150px">Amount</td>
									<td className="min-w-150px">Status</td>
									<td className="min-w-150px">Billing Date</td>
									<td className="min-w-150px">Next Due Date</td>
									<td className="min-w-150px">Invoice</td>
								</tr>
							</thead>
							{loading ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: '100%',
									}}
								>
									<Spinner />
								</div>
							) : billingHistory?.length > 0 ? (
								<tbody className="fw-semibold text-gray-600">
									{billingHistory.map((item) => {
										const {
											id,
											createdAt,
											amount,
											currency,
											plan,
											description,
											invoice,
											invoicePdf,
											status,
											billingDate,
											nextDueDate,
										} = item

										return (
											<tr key={id}>
												<td>{dayjs(createdAt).format('MMM DD, YYYY')}</td>
												<td>{plan}</td>
												<td>{description}</td>
												<td>{`${currency}${amount}`}</td>
												<td>{status}</td>
												<td>{billingDate}</td>
												<td>{nextDueDate}</td>
												<td>
													{invoicePdf ? (
														<Link
															to={invoicePdf}
															className="btn btn-sm btn-light btn-active-light-info"
														>
															Download
														</Link>
													) : (
														<button
															disabled={invoiceLoading === invoice}
															onClick={() =>
																invoice && invoiceDownloadHandler(invoice)
															}
															type="button"
															className="btn btn-sm btn-light btn-active-light-info"
														>
															Download
														</button>
													)}
												</td>
											</tr>
										)
									})}
								</tbody>
							) : (
								<tbody>
									<tr>
										<td colSpan="8" className="text-center">
											No Data Found
										</td>
									</tr>
								</tbody>
							)}
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BillingHistory
