import React, { useState } from 'react'
import {
	FormGroup,
	Label,
	Input,
	Col,
	Row,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap'
import { Controller } from 'react-hook-form'
import useCustomLinks from '../../../hooks/useCustomLinks'
import SharedApi from '../../../services/http/shared'
import { APP_ROUTES } from '../../../config/constants'
import { Tooltip } from 'reactstrap'

export const urlBuilder = (customLink) => {
	const { domain, subDomain, spare } = customLink
	try {
		const url = new URL(domain)
		return spare === 'No' ? `${subDomain}.${url.host}` : url.host
	} catch (e) {
		console.log('Error while creating url : ', e)
		return domain
	}
}

const AddNewTitleLink = ({ control, form }) => {
	const BASE_URL = process.env.REACT_APP_QR_BASE_URL
	const {
		trigger,
		formState: { errors },
		setError,
		watch,
	} = form
	const watchFields = watch(['brandedDomain', 'passwordProtectionEnabled'])
	const customLinks = useCustomLinks()
	const [tooltipOpen, setTooltipOpen] = useState(false)

	const toggleTooltip = () => {
		setTooltipOpen(!tooltipOpen)
	}

	const slugValidityHandler = async (value) => {
		try {
			const response = await SharedApi.isSlugValid({ slug: value })
			if (!response?.data?.success) {
				setError('slashTag', {
					type: 'manual',
					message: 'Slash tag already exist!',
				})
			}
		} catch (e) {
			console.log('Error while checking slug validity: ', e.message)
		}
	}

	return (
		<>
			<Row className="mb-4">
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">
							Title for your link{' '}
							<span className="text-gray-400">(Optional)</span>
						</Label>
						<Controller
							name="title"
							control={control}
							render={({ field }) => (
								<>
									<Input
										{...field}
										onChange={(e) => {
											field.onChange(e)
										}}
										placeholder="Enter your title"
										className="h-50px"
									/>
								</>
							)}
						/>
					</FormGroup>
				</Col>
				<Col md={6}>
					<div className="d-flex ">
						<label className="fw-bold fs-5 mb-2 w-50">Branded domain</label>
						<label className="fw-bold fs-5 mb-2 ps-5">Slash tag</label>
					</div>
					<div className="input-group justify-content-between h-50px">
						<div className="custom-dropdown form-control p-0">
							<Controller
								name="brandedDomain"
								control={control}
								render={({ field, fieldState }) => {
									return (
										<div>
											<UncontrolledDropdown direction="down">
												<DropdownToggle
													caret
													color="transparent"
													className="h-48px w-100 d-flex justify-content-between align-items-center"
												>
													<span>{field.value || BASE_URL}</span>
													<span className="caret-icon ms-auto"></span>{' '}
													{/* Optional: To add custom icon class */}
												</DropdownToggle>
												<DropdownMenu>
													{customLinks &&
														customLinks.length > 0 &&
														customLinks?.map((dom) => (
															<DropdownItem
																onClick={() => field.onChange(urlBuilder(dom))}
															>
																{urlBuilder(dom)}
															</DropdownItem>
														))}
												</DropdownMenu>
											</UncontrolledDropdown>
											{fieldState.error ? (
												<span className="error-text text-danger">
													{fieldState.error.message}
												</span>
											) : null}
										</div>
									)
								}}
							/>
						</div>

						<Controller
							name="slashTag"
							control={control}
							autoComplete="off"
							render={({ field }) => {
								return (
									<div>
										<Input
											{...field}
											autoComplete="off"
											style={{
												width: '180px',
												borderRadius: '0px 5px 5px 0px',
											}}
											className="h-50px"
											onChange={async (e) => {
												field.onChange(e)
												trigger('slashTag')
												if (APP_ROUTES.includes(e.target.value.toLowerCase())) {
													console.log(e.target.value, 'value')
													setError('slashTag', {
														type: 'manual',
														message: 'Slash tag already exist!',
													})
												} else {
													await slugValidityHandler(
														`${watchFields.brandedDomain}/${e.target.value}`
													)
												}
											}}
											placeholder="Generated Slash Tag"
										/>
									</div>
								)
							}}
						/>
					</div>

					{errors.slashTag ? (
						<div style={{ textAlign: 'end' }}>
							<span className="error-text text-danger">
								{errors.slashTag.message}
							</span>
						</div>
					) : null}
				</Col>
			</Row>
			<Row className="mb-7">
				<Col md={6}>
					<Label className="fs-5 fw-bold mb-0 d-flex align-items-center user-select-none">
						<Controller
							name="passwordProtectionEnabled"
							control={control}
							render={({ field }) => (
								<div className="form-check form-check-sm form-check-custom form-check-info form-check-solid not-checked-white rounded-1">
									<Input
										{...field}
										type="checkbox"
										className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 me-2 border-gray-300 text-indigo-600 focus:ring-indigo-600"
										value={field.value}
										checked={watchFields[1]}
										onChange={async (e) => {
											field.onChange(e)
											trigger('passwordProtectionEnabled')
										}}
									/>
								</div>
							)}
						/>
						Password protection{' '}
						<i className="fas fa-circle-info ms-2" id="passwordpicon"></i>
						<Tooltip
							isOpen={tooltipOpen}
							target="passwordpicon"
							toggle={toggleTooltip}
						>
							Secure your Short Link with password
						</Tooltip>
					</Label>
				</Col>
				<Col md={6}>
					{watchFields[1] ? (
						<div>
							<Controller
								name="password"
								control={control}
								autoComplete="off"
								autocomplete="one-time-code"
								render={({ field }) => {
									return (
										<div>
											<Input
												{...field}
												autoComplete="off"
												autocomplete="one-time-code"
												className="h-50px"
												onChange={async (e) => {
													field.onChange(e)
													trigger('password')
												}}
												placeholder="Enter password"
												type="password"
											/>
										</div>
									)
								}}
							/>
							{errors.password ? (
								<div style={{ textAlign: 'end' }}>
									<span className="error-text text-danger">
										{errors.password.message}
									</span>
								</div>
							) : null}
						</div>
					) : null}
				</Col>
			</Row>
		</>
	)
}

export default AddNewTitleLink
