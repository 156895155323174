import { parsePhoneNumberFromString } from 'libphonenumber-js'

const calculatePhoneNumber = (number) => {
	if (number) {
		const parsedNumber = parsePhoneNumberFromString(`+${number}`)
		if (parsedNumber && parsedNumber.isValid()) {
			return parsedNumber.formatInternational() // National part of the number
		} else {
			return false
		}
	}
}

export default calculatePhoneNumber
