import { useContext } from 'react'
import Email from '../sign-in-method/email'
import Password from '../sign-in-method/password'
import { AuthContxt } from '../../../store/authContxt'
import { AccountType } from '../../../utils/enum'

const SignInMethod = () => {
	const { profile, setProfile } = useContext(AuthContxt)
	return (
		<div className="card  mb-5 mb-xl-10">
			<div
				className="card-header min-h-lg-70px min-h-50px px-5 px-lg-10 py-5 border-0 cursor-pointer"
				role="button"
			>
				<div className="card-title m-0">
					<h3 className="fw-bold m-0">Sign-in Method</h3>
				</div>
			</div>

			<div className="collapse show">
				<div className="card-body border-top p-lg-9 p-5 pb-lg-0 pb-0">
					<Email
						edittedProfile={profile}
						profileHandler={(data) =>
							setProfile({ ...profile, email: data?.email })
						}
					/>
					<div className="separator separator-dashed my-6"></div>
					<Password />
					{profile?.method === AccountType.GOOGLE ? (
						<div style={{ textAlign: 'end', marginBottom: '10px' }}>
							<span className="error-text text-info">
								{'You are currently using Google Sign-in to login.'}
							</span>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}
export default SignInMethod
