const formatAndValidateUrl = (url) => {
	try {
		// If the URL starts with "www." but lacks a protocol, prepend "http://"
		if (/^www\./i.test(url)) {
			url = `https://${url}`
		}

		// If the URL doesn't start with a protocol, prepend "http://"
		if (!/^((ftp|http|https):\/\/)/i.test(url)) {
			url = `https://${url}`
		}

		// Use the URL constructor to validate and format the URL
		const validUrl = new URL(url)

		// Validate the hostname to ensure it's a proper domain with TLD
		if (!/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(validUrl.hostname)) {
			return null // Invalid URL
		}

		// Return the properly formatted URL (normalized with protocol)
		return validUrl.href
	} catch (error) {
		return null // Invalid URL
	}
}

export default formatAndValidateUrl
