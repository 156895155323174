import React, { useCallback, useContext, useState } from 'react'
import _debounce from 'lodash/debounce'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import QrCodeList from './qr-code-list'
import {
	Input,
	Spinner,
	Tooltip,
	Modal,
	ModalBody,
	ModalHeader,
} from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { QrCodeContxt } from './qrCodeContxt'
import { AuthContxt } from '../../store/authContxt'
import './CardGrid.css' // You can create a separate CSS file or use inline styles

const SORTS = [
	{ label: 'A - Z', value: 'a-z' },
	{ label: 'Z- A', value: 'z-a' },
	{ label: 'Oldest', value: 'oldest' },
	{ label: 'Latest', value: 'latest' },
	{ label: 'Most Scanned', value: 'mostScanned' },
]

const QrCodesHeader = ({ count }) => {
	const { tags, filters, setFilters, fetchFilteredData, search, setSearch } =
		useContext(QrCodeContxt)

	const { quota } = useContext(AuthContxt)

	// Enabling the creation based on quota
	const isLimitReached = quota
		? quota?.qrCode <= 0 || quota?.shortlink <= 0
		: true

	const [tooltipOpen, setTooltipOpen] = useState(false)

	const navigate = useNavigate()
	const [showSearch, setShowSearch] = useState(false)
	const [showSearchmob, setShowSearchmob] = useState(true)
	const [loading, setLoading] = useState(false)
	const [modal, setModal] = useState(false)
	const toggle1 = () => setModal(!modal)

	const fetchFilteredDataHandler = async (filters) => {
		setLoading(true)
		await fetchFilteredData(filters)
		setLoading(false)
	}

	const filtersHandler = (key, value) => {
		setFilters((preState) => {
			return { ...preState, [key]: value }
		})
		fetchFilteredDataHandler({ ...filters, [key]: value, search })
	}

	const handleSearch = useCallback(
		_debounce(async (filters) => fetchFilteredDataHandler(filters), 500),
		[]
	)

	const toggle = () => {
		if (isLimitReached) {
			setTooltipOpen(!tooltipOpen)
		}
	}

	const searchHandler = (search) => {
		handleSearch({ ...filters, search })
		setSearch(search)
	}

	return (
		<div className="px-6 px-lg-8 py-lg-8 py-5 d-flex items-center justify-content-between w-100 align-items-center">
			<h2 className="pl-4 mb-0 d-flex align-items-center fs-1">
				QR Codes{' '}
				<span className="badge bg-light-danger text-danger fs-5 border ms-3 rounded-1">
					{count}
				</span>
			</h2>
			<div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons">
				{loading ? <Spinner /> : null}
				<InputGroup className="mb-0 w-auto">
					{showSearch ? (
						<Form.Control
							className="shortlinkinputsearch headerinputsearch fw-normal"
							placeholder="Search here ..."
							aria-label="Search here ..."
							aria-describedby="basic-addon2"
							onChange={(e) => {
								searchHandler(e.target.value)
							}}
						></Form.Control>
					) : null}
					<InputGroup.Text
						id="basic-addon2"
						className="cursor-pointer btn btn-icon btn btn-icon bg-light-info rounded-1 "
						onClick={() => {
							setShowSearch(!showSearch)
						}}
					>
						<i className="fa-regular fa-magnifying-glass text-info fs-3"></i>
					</InputGroup.Text>
				</InputGroup>
				<Dropdown className="d-inline nospce">
					<Dropdown.Toggle
						id="dropdown-autoclose-false"
						className="btn btn-icon btn btn-icon bg-light-info rounded-1"
						title="Tags"
						variant="bg-light"
					>
						<i className="fa-regular fa-tags text-info fs-3"></i>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{tags && tags.length > 0 ? (
							tags?.map((tag) => (
								<Dropdown.Item key={tag}>
									<div
										onClick={() => {
											const filteredTags = filters.tags.includes(tag)
												? filters.tags.filter((item) => item !== tag)
												: [...filters.tags, tag]
											filtersHandler('tags', filteredTags)
										}}
										className="d-flex justify-content-between align-items-center cursor-pointer"
									>
										<div class="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
											<label class="form-check-label text-dark me-3">
												{tag}
											</label>
											<Input
												class="form-check-input n-checked-white-hvr rounded-1 tag-chk"
												type="checkbox"
												checked={filters.tags.includes(tag)}
											/>
										</div>
									</div>
								</Dropdown.Item>
							))
						) : (
							<span style={{ marginLeft: '10px' }}>No tag found!</span>
						)}
					</Dropdown.Menu>
				</Dropdown>

				<Dropdown className="d-inline nospce">
					<Dropdown.Toggle
						id="dropdown-autoclose-true"
						className="btn btn-icon btn btn-icon bg-light-info rounded-1"
						title="Sort By"
						variant="bg-light"
					>
						<i className="fa-regular fa-arrow-up-wide-short text-info fs-3"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{SORTS.map((item) => {
							const { label, value } = item
							return (
								<Dropdown.Item onClick={() => filtersHandler('sort', value)}>
									{label}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>

				<div onMouseOver={toggle} onMouseLeave={toggle} id="plusIcon">
					<button
						type="button"
						title="Add New QR Code"
						className="btn btn-icon rounded-1 btn-info"
						color="danger"
						disabled={isLimitReached}
						onClick={() => {
							if (!isLimitReached) {
								navigate('/list-qr-codes') // call toggle directly if the limit is not reached
							}
						}}
					>
						<i className="fa-solid fa-plus text-white fs-1"></i>
					</button>
				</div>

				<Tooltip
					placement={'top'}
					isOpen={tooltipOpen}
					target={'plusIcon'}
					toggle={toggle}
				>
					{quota
						? `Your Monthly QR Code Limit Reached. Please Upgrade your Plan`
						: `Loading...`}
				</Tooltip>
			</div>
			<div className="desktop-hide-f align-items-center justify-content-center">
				<div className="shortlinkMenu mt-0">
					<button
						type="button"
						title="more"
						className="btn btn-icon rounded-1 border bg-light-info"
					>
						<i className="fa-regular fa-magnifying-glass text-info fs-3"></i>
					</button>

					<div className="dropdown-content mobsearchbardropdown border bg-white rounded-1 py-5 end--50px">
						<div className="card-toolbar d-flex flex-wrap justify-content-center lg:flex-row align-items-center gap-5 me-0 mb-0 h-10">
							<InputGroup className="mb-0">
								{showSearchmob ? (
									<Form.Control
										placeholder="Search...."
										className="headerinputsearch rounded-1 border"
										aria-label="Recipient's username"
										aria-describedby="basic-addon2"
										onChange={(e) => {
											searchHandler(e.target.value)
										}}
									></Form.Control>
								) : (
									showSearchmob
								)}
								<InputGroup.Text
									id="basic-addon2"
									className="cursor-pointer btn btn-icon rounded-1 d-none bg-light-info"
								>
									<i className="fa-solid fa-magnifying-glass text-info fs-3"></i>
								</InputGroup.Text>
							</InputGroup>
						</div>
					</div>
				</div>
				<div className="position-absolute d-flex justify-content-around mobbottommenu">
					<Dropdown className="d-inline nospce">
						<Dropdown.Toggle
							id="dropdown-autoclose-false"
							className="btn btn-icon btn btn-icon bg-light-info rounded-1"
							title="Tags"
							variant="bg-light"
						>
							<i className="fa-regular fa-tags text-info fs-3"></i>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{tags && tags.length > 0 ? (
								tags?.map((tag) => (
									<Dropdown.Item key={tag}>
										<div
											onClick={() => {
												const filteredTags = filters.tags.includes(tag)
													? filters.tags.filter((item) => item !== tag)
													: [...filters.tags, tag]
												filtersHandler('tags', filteredTags)
											}}
											className="d-flex justify-content-between align-items-center cursor-pointer"
										>
											<div class="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
												<label class="form-check-label text-dark me-3">
													{tag}
												</label>
												<Input
													class="form-check-input n-checked-white-hvr rounded-1 tag-chk"
													type="checkbox"
													checked={filters.tags.includes(tag)}
												/>
											</div>
										</div>
									</Dropdown.Item>
								))
							) : (
								<span style={{ marginLeft: '10px' }}>No tag found!</span>
							)}
						</Dropdown.Menu>
					</Dropdown>

					<Dropdown className="d-inline nospce">
						<Dropdown.Toggle
							id="dropdown-autoclose-true"
							className="btn btn-icon btn btn-icon bg-light-info rounded-1"
							title="Sort By"
							variant="bg-light"
						>
							<i className="fa-regular fa-arrow-up-wide-short text-info fs-3"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{SORTS.map((item) => {
								const { label, value } = item
								return (
									<Dropdown.Item onClick={() => filtersHandler('sort', value)}>
										{label}
									</Dropdown.Item>
								)
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>

				<div onMouseOver={toggle} onMouseLeave={toggle} id="plusIcon">
					<button
						type="button"
						title="Add New QR Code"
						className="btn btn-icon btn-info rounded-circle mobile-add-link-btn desktop-hide"
						color="danger"
						disabled={isLimitReached}
						onClick={() => {
							if (!isLimitReached) {
								toggle1() // call toggle directly if the limit is not reached
							}
						}}
					>
						Add New QR Code
					</button>
				</div>

				<Tooltip
					placement={'top'}
					isOpen={tooltipOpen}
					target={'plusIcon'}
					className="desktop-hide"
					toggle={toggle}
				>
					{`Your Monthly QR Code Limit Reached. Please Upgrade your Plan`}
				</Tooltip>
			</div>
		</div>
	)
}

export default QrCodesHeader
