import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Label, Input, Button, Spinner } from 'reactstrap'
import Select from 'react-select'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ContactApi from '../../services/http/contact'
import { GetCountries, GetState, GetCity } from 'react-country-state-city'
import calculatePhoneNumber from '../../utils/extractPhoneNumber'

const ContactForm = (props) => {
	let {
		toggleForm,
		reload,
		setReload,
		userId,
		contactForUpdate,
		setContactForUpdate,
		handleResetPage,
		setSelectedTags,
		setSortOrder,
	} = props

	let userData = localStorage?.getItem('user_details')

	const [tags, setTags] = useState([])
	const [location, setLocation] = useState({
		country: null,
		state: null,
		city: null,
	})

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		job: '',
		department: '',
		mobile: '',
		whatsapp: '',
	})

	const [formErrors, setFormErrors] = useState({
		firstName: '',
		email: '',
		mobile: '',
		whatsapp: '',
	})

	const [countriesList, setCountriesList] = useState([])
	const [stateList, setStateList] = useState([])
	const [cityList, setCityList] = useState([])

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		GetCountries().then((result) => {
			setCountriesList(result)
		})
	}, [])

	useEffect(() => {
		if (contactForUpdate && countriesList.length > 0) {
			// Set initial form data
			setFormData({
				firstName: contactForUpdate.firstName || '',
				lastName: contactForUpdate.lastName || '',
				email: contactForUpdate.email || '',
				job: contactForUpdate.job || '',
				department: contactForUpdate.department || '',
				mobile: contactForUpdate.mobile || '',
				whatsapp: contactForUpdate.whatsapp || '',
			})

			// Set initial values for country, state, and city based on update data
			const country = countriesList.find(
				(item) => item.name === contactForUpdate.country
			)
			if (country) {
				setLocation((prev) => ({ ...prev, country }))
				GetState(country.id).then((states) => {
					setStateList(states)
					const state = states.find(
						(item) => item.name === contactForUpdate.state
					)
					if (state) {
						setLocation((prev) => ({ ...prev, state }))
						GetCity(country.id, state.id).then((cities) => {
							setCityList(cities)
							const city = cities.find(
								(item) => item.name === contactForUpdate.city
							)
							if (city) {
								setLocation((prev) => ({ ...prev, city }))
							}
						})
					}
				})
			}

			// Set initial tags
			setTags(contactForUpdate.tags.map((tag) => tag.name))
		}
	}, [contactForUpdate, countriesList]) // Dependency on countriesList ensures it runs after countries are loaded

	const handleValueChange = (e) => {
		const { name, value } = e.target
		setFormData((prev) => ({ ...prev, [name]: value }))
		setFormErrors((prev) => ({ ...prev, [name]: '' }))
	}

	const handleCountryChange = (selectedOption) => {
		const country = countriesList.find(
			(item) => item.id === selectedOption.value
		)
		setLocation((prev) => ({ ...prev, country, state: null, city: null }))
		setStateList([])
		setCityList([])
		GetState(country.id).then((states) => {
			setStateList(states)
		})
	}

	const handleStateChange = (selectedOption) => {
		const state = stateList.find((item) => item.id === selectedOption.value)
		setLocation((prev) => ({ ...prev, state, city: null }))
		setCityList([])
		GetCity(location.country.id, state.id).then((cities) => {
			setCityList(cities)
		})
	}

	const handleCityChange = (selectedOption) => {
		const city = cityList.find((item) => item.id === selectedOption.value)
		setLocation((prev) => ({ ...prev, city }))
	}

	const handleTagChange = (tagsInput) => {
		const splitTags = tagsInput
			.join(',')
			.split(',')
			.map((tag) => tag.trim())
			.filter((tag) => tag !== '')
		const uniqueTags = [...new Set(splitTags)]
		setTags(uniqueTags)
	}

	const validateForm = () => {
		const errors = {}
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

		// Check for email
		if (
			!formData?.email ||
			typeof formData.email !== 'string' ||
			!formData.email.trim()
		) {
			errors.email = 'Email is required'
		} else if (!emailRegex.test(formData.email.trim())) {
			errors.email = 'Email is not valid'
		}

		// Check for firstName
		if (
			!formData?.firstName ||
			typeof formData.firstName !== 'string' ||
			!formData.firstName.trim()
		) {
			errors.firstName = 'First Name is required'
		}

		// Check for mobile
		if (
			!formData?.mobile ||
			typeof formData.mobile !== 'string' ||
			!formData.mobile.trim()
		) {
			errors.mobile = 'Mobile is required'
		}

		setFormErrors(errors)
		return Object.keys(errors).length === 0
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault()

		setLoading(true)
		if (!validateForm()) {
			setLoading(false)
			return
		}

		console.log('formData: ', formData)

		if (!calculatePhoneNumber(formData.mobile)) {
			setFormErrors((old) => ({ ...old, mobile: 'Invalid phone number' }))
			setLoading(false)
			return
		}

		if (formData.whatsapp && !calculatePhoneNumber(formData.whatsapp)) {
			setFormErrors((old) => ({ ...old, whatsapp: 'Invalid whatsapp number' }))
			setLoading(false)
			return
		}

		userData = JSON.parse(userData)
		const payload = {
			...formData,
			country: location.country?.name,
			state: location.state?.name,
			city: location.city?.name,
			tags,
			userId: userData.id,
		}

		try {
			let response

			if (contactForUpdate) {
				response = await ContactApi.updateContact({
					...payload,
					contactId: contactForUpdate.id,
				})
			} else {
				response = await ContactApi.createContact(payload)
			}
			if (response.status === 201 || response.status === 200) {
				setReload(!reload)
				toggleForm()
				setFormData({
					firstName: '',
					lastName: '',
					email: '',
					job: '',
					department: '',
					mobile: '',
					whatsapp: '',
				})
				setTags([])
				setLocation({
					city: null,
					state: null,
					country: null,
				})
				setContactForUpdate(null)
				setSelectedTags([])
				setSortOrder()
				handleResetPage()
				setLoading(false)
			}

			setLoading(false)
		} catch (error) {
			let response = error?.response
			if (response?.status === 409) {
				let conflictError = response?.data?.error
				setFormErrors((old) => ({
					...old,
					email: conflictError,
					mobile: conflictError,
				}))
			}
			setLoading(false)
			console.error(error)
		}
	}

	console.log('formErrors: ', formErrors)

	return (
		<form>
			<Row>
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">First Name</Label>
						<Input
							type="text"
							name="firstName"
							value={formData.firstName}
							onChange={handleValueChange}
							className="form-control rounded-1 h-50px"
						/>
						{formErrors.firstName && (
							<span className="text-danger">{formErrors.firstName}</span>
						)}
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">Last Name</Label>
						<Input
							type="text"
							name="lastName"
							value={formData.lastName}
							onChange={handleValueChange}
							className="form-control rounded-1 h-50px"
						/>
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">Email Address</Label>
						<Input
							type="email"
							name="email"
							value={formData.email}
							onChange={handleValueChange}
							className="form-control rounded-1 h-50px"
						/>
						{formErrors.email && (
							<span className="text-danger">{formErrors.email}</span>
						)}
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">Mobile Number</Label>
						<PhoneInput
							name="mobile"
							value={formData.mobile}
							onChange={(value) => {
								if (formErrors.mobile) {
									setFormErrors((old) => ({
										...old,
										mobile: '',
									}))
								}
								setFormData((prev) => ({ ...prev, mobile: value }))
							}}
							className="form-control rounded-1 h-50px contact-form"
							country={'in'}
							placeholder="Phone number"
							enableSearch={true}
						/>
						{formErrors.mobile && (
							<span className="text-danger">{formErrors.mobile}</span>
						)}
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">WhatsApp Number</Label>
						<PhoneInput
							name="whatsapp"
							value={formData.whatsapp}
							onChange={(value) => {
								if (formErrors.whatsapp) {
									setFormErrors((old) => ({
										...old,
										whatsapp: '',
									}))
								}
								setFormData((prev) => ({ ...prev, whatsapp: value }))
							}}
							className="form-control rounded-1 h-50px contact-form"
							country={'in'}
							placeholder="Phone number"
							enableSearch={true}
						/>
						{formErrors.whatsapp && (
							<span className="text-danger">{formErrors.whatsapp}</span>
						)}
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">Job</Label>
						<Input
							type="text"
							name="job"
							value={formData.job}
							onChange={handleValueChange}
							className="form-control rounded-1 h-50px"
						/>
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">Department</Label>
						<Input
							type="text"
							name="department"
							value={formData.department}
							onChange={handleValueChange}
							className="form-control rounded-1 h-50px"
						/>
					</FormGroup>
				</Col>
				{/* Country Selection */}
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">Country</Label>
						<Select
							options={countriesList.map((country) => ({
								value: country.id,
								label: country.name,
							}))}
							value={
								location.country
									? { value: location.country.id, label: location.country.name }
									: null
							}
							onChange={handleCountryChange}
							className="react-select-container"
							classNamePrefix="react-select"
						/>
					</FormGroup>
				</Col>
				{/* State Selection */}
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">State</Label>
						<Select
							options={stateList.map((state) => ({
								value: state.id,
								label: state.name,
							}))}
							value={
								location.state
									? { value: location.state.id, label: location.state.name }
									: null
							}
							onChange={handleStateChange}
							className="react-select-container"
							classNamePrefix="react-select"
							isDisabled={!location.country}
						/>
					</FormGroup>
				</Col>
				{/* City Selection */}
				<Col md={6}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">City</Label>
						<Select
							options={cityList.map((city) => ({
								value: city.id,
								label: city.name,
							}))}
							value={
								location.city
									? { value: location.city.id, label: location.city.name }
									: null
							}
							onChange={handleCityChange}
							className="react-select-container"
							classNamePrefix="react-select"
							isDisabled={!location.state}
						/>
					</FormGroup>
				</Col>
				<Col md={12}>
					<FormGroup>
						<Label className="fs-5 fw-bold mb-2">Tags</Label>
						<TagsInput
							value={tags}
							onChange={handleTagChange}
							inputProps={{
								placeholder: 'Type and press enter or comma',
								style: { width: '-webkit-fill-available' },
							}}
							onlyUnique
							addOnBlur // Optional: Adds the tag when the input loses focus
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md={12} className="ms-auto text-end btn-info">
					<Button
						type="submit"
						color="primary"
						onClick={handleFormSubmit}
						disabled={loading}
						className="btn btn-info h-50px mt-10 w-100 d-block rounded-1 text-white"
					>
						{loading ? (
							<>
								<Spinner />
							</>
						) : (
							<>{contactForUpdate ? 'Update Contact' : 'Create Contact'}</>
						)}
					</Button>
				</Col>
			</Row>
		</form>
	)
}

export default ContactForm
