import './assets/style.css'
import divslyicon from './assets/divsly-c.svg'
import { Link } from 'react-router-dom'
import { SOCIAL_MAPPER } from '../customization/socialDropdown'
import { getBackground, getButton } from '../create-page/stylesMapper'

const Layout1 = ({ values }) => {
	const {
		avatar,
		links,
		name,
		bio,
		bg,
		bgType,
		socials,
		fontClr,
		shadowClr,
		button,
		hideLogo,
		descriptionClr,
		titleClr,
		theme,
		btnClr,
		font,
	} = values
	return (
		<div
			linkinbiotheme={theme}
			className="layoutone"
			style={{
				fontFamily: font,
				position: 'relative',
				...(bgType !== 'video'
					? {
							...getBackground(bg)[bgType],
						}
					: {}),
			}}
		>
			<div className="main-container">
				{bgType === 'video' && bg !== '' ? (
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							left: 0,
						}}
					>
						<video
							key={bg}
							width={'100%'}
							height={'100%'}
							controls={false}
							autoPlay
							muted
							loop
						>
							<source src={bg} type="video/mp4" />
						</video>
					</div>
				) : null}

				<div className="theme-main-container" style={{ zIndex: 9 }}>
					<div className="profile_container">
						<div className="bio_background"></div>
						<div className="d-flex flex-column align-items-center">
							<div className="mb-4 profile_img">
								<img src={avatar || '/placeholder.png'} alt={name} />
							</div>
							<div style={{ width: '-webkit-fill-available' }}>
								<div className="d-flex align-items-center justify-content-center mx-3">
									<h1
										className="profile_name"
										style={{
											color: titleClr,
										}}
									>
										{name}
									</h1>
								</div>
								<div className="px-10 mt-1">
									<h2
										className="profile_description"
										style={{
											color: descriptionClr,
										}}
									>
										{bio}
									</h2>
								</div>
							</div>
						</div>
						<div className="social_icons">
							{socials
								?.filter(
									(social) =>
										social?.isValid && social?.url !== '' && social?.id !== ''
								)
								.map((social, index) => {
									return (
										<Link
											key={index}
											to={social?.url || '#'}
											className="social_icon"
										>
											<img
												src={SOCIAL_MAPPER[social?.id]?.icon}
												alt={'Social'}
												className="rounded-circle h-20px me-2"
											/>
										</Link>
									)
								})}
						</div>
					</div>
					<div className="buttons_list">
						{links
							?.filter((link) => link?.isActive)
							?.map((item) => {
								const { btnLabel, brandedDomain, slashTag } = item
								return (
									<button
										className="link_button"
										style={{
											...getButton(btnClr, shadowClr)[button],
											display: 'flex', // Ensures proper layout if needed
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
										onClick={() =>
											window.open(
												`https://${brandedDomain}/${slashTag}?r=lb`,
												'_blank'
											)
										} // Opens the link in a new tab
									>
										<span
											className="link_text"
											style={{
												color: fontClr,
											}}
										>
											{btnLabel}
										</span>
									</button>
								)
							})}
					</div>
				</div>
				{!hideLogo ? (
					<div className="divsly_brand">
						<Link to="https://divsly.com">
							<img style={{ width: '80px' }} src={divslyicon} alt="" />
						</Link>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default Layout1
