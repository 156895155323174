import { useContext, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { Spinner } from 'reactstrap'
import UserSettingsApi from '../../../services/http/userSettings'
import { FormGroup, Label, Input } from 'reactstrap'
import { AccountType, PASSWORD_REGEX } from '../../../utils/enum'
import { AuthContxt } from '../../../store/authContxt'

const updatePasswordValidation = yup.object().shape({
	password: yup.string().required('Please enter your password'),
	newPassword: yup
		.string()
		.required('Please enter new password')
		.matches(
			PASSWORD_REGEX,
			'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
		),
	confirmPassword: yup
		.string()
		.required('Please enter new password')
		.oneOf([yup.ref('newPassword'), null], 'Password should be match'),
})

const Password = () => {
	const { profile } = useContext(AuthContxt)
	const [message, setMessage] = useState()
	const [loading, setLoading] = useState(false)
	const [showForm, setShowForm] = useState(false)

	const form = useForm({
		resolver: yupResolver(updatePasswordValidation),
		defaultValues: {
			password: '',
			newPassword: '',
			confirmPassword: '',
		},
	})
	const {
		handleSubmit,
		setValue,
		reset,
		control,
		formState: { errors },
		trigger,
	} = form

	const resetErrorMessage = () => {
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const submitHandler = async (data) => {
		if (data) {
			try {
				setLoading(true)
				const response = await UserSettingsApi.updatePassword(data)
				if (response?.data?.error) {
					setMessage(response.data?.message || 'Something went wrong')
					setLoading(false)
				} else {
					setMessage('Password updated successfully')
					setLoading(false)
					reset()
					setShowForm(false)
				}
				resetErrorMessage()
			} catch (e) {
				setMessage(e?.response?.data?.message || 'Something went wrong')
				console.log('Error while updating password: ', e)
				setLoading(false)
				resetErrorMessage()
			}
		}
	}

	return (
		<div className="d-flex flex-wrap align-items-center mb-9">
			<div
				className={
					showForm ? 'flex-row-fluid d-none' : 'flex-row-fluid d-block'
				}
			>
				<div className="fs-6 fw-bold mb-1">Password</div>
				<div className="fw-semibold text-gray-600">************</div>
			</div>

			<div
				className={
					showForm ? 'flex-row-fluid d-block' : 'flex-row-fluid d-none'
				}
			>
				<form
					className="form fv-plugins-bootstrap5 fv-plugins-framework"
					onSubmit={handleSubmit(submitHandler)}
				>
					<div className="row mb-1">
						<div className="col-lg-4">
							<div className="fv-row mb-0 fv-plugins-icon-container">
								<FormGroup>
									<Label className="fs-5 fw-bold mb-2">Current Password</Label>
									<Controller
										name={'password'}
										control={control}
										render={({ field: { onBlur, value } }) => (
											<Input
												value={value}
												type="password"
												className="form-control form-control-lg border rounded-1 form-control-solid "
												placeholder="Password"
												onChange={(e) => {
													setValue('password', e.target.value)
													trigger('password')
												}}
												onBlur={onBlur}
											/>
										)}
									/>
								</FormGroup>
								{errors.password ? (
									<div style={{ textAlign: 'end' }}>
										<span className="error-text text-danger">
											{errors.password.message}
										</span>
									</div>
								) : null}
							</div>
						</div>

						<div className="col-lg-4">
							<div className="fv-row mb-0 fv-plugins-icon-container">
								<FormGroup>
									<Label className="fs-5 fw-bold mb-2">New Password</Label>
									<Controller
										name={'newPassword'}
										control={control}
										render={({ field: { onBlur, value } }) => (
											<Input
												value={value}
												type="password"
												className="form-control form-control-lg border rounded-1 form-control-solid "
												placeholder="New Password"
												onChange={(e) => {
													setValue('newPassword', e.target.value)
													trigger('newPassword')
												}}
												onBlur={onBlur}
											/>
										)}
									/>
								</FormGroup>
								{errors.newPassword ? (
									<div style={{ textAlign: 'end' }}>
										<span className="error-text text-danger">
											{errors.newPassword.message}
										</span>
									</div>
								) : null}
							</div>
						</div>

						<div className="col-lg-4">
							<div className="fv-row mb-0 fv-plugins-icon-container">
								<FormGroup>
									<Label className="fs-5 fw-bold mb-2">Confirm Password</Label>
									<Controller
										name={'confirmPassword'}
										control={control}
										render={({ field: { onBlur, value } }) => (
											<Input
												value={value}
												type="password"
												className="form-control form-control-lg border rounded-1 form-control-solid "
												placeholder="New Password"
												onChange={(e) => {
													setValue('confirmPassword', e.target.value)
													trigger('confirmPassword')
												}}
												onBlur={onBlur}
											/>
										)}
									/>
								</FormGroup>
								{errors.confirmPassword ? (
									<div style={{ textAlign: 'end' }}>
										<span className="error-text text-danger">
											{errors.confirmPassword.message}
										</span>
									</div>
								) : null}
							</div>
						</div>
					</div>

					<div className="form-text mb-5">
						Password must be at least 8 character and contain symbols
					</div>

					<div
						style={{
							textAlign: 'start',
						}}
					>
						{message ? (
							<span className="text-danger fs-6 fw-bold">{message}</span>
						) : null}
					</div>

					<div className="d-flex">
						<button
							id="kt_password_cancel"
							type="button"
							onClick={() => setShowForm(false)}
							className="btn btn-color-gray-500 border rounded-1 btn-active-light-info px-6 me-4"
						>
							Cancel
						</button>
						<button
							id="kt_password_submit"
							type="submit"
							className="btn btn-info rounded-1 px-6"
						>
							{loading ? <Spinner /> : 'Update Password'}
						</button>
					</div>
				</form>
			</div>

			<div id="kt_signin_password_button" className="ms-auto">
				<button
					className={
						showForm
							? 'btn btn-light btn-active-light-info rounded-1 d-none'
							: 'btn btn-light btn-active-light-info rounded-1 d-block'
					}
					onClick={() =>
						profile?.method !== AccountType.GOOGLE && setShowForm(true)
					}
					disabled={profile?.method === AccountType.GOOGLE}
				>
					Change Password
				</button>
			</div>
		</div>
	)
}

export default Password
