import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, TabPane, Input } from 'reactstrap'
import QrCodesAPI from '../../../services/http/qrCodes'
import StickyButton from './stickyqrviewbtn'
import LogoCropper from './logoCropper'
import { AuthContxt } from '../../../store/authContxt'
import { Tooltip } from 'reactstrap'
import { SvgIcons } from './frames-svg'
import { QrCodeContxt } from '../qrCodeContxt'
import logoWhiteBg from '../../../assets/logos/logo-with-white-bg.png'
import pattern1 from '../../../assets/qr-pattern/pattern-1.png'
import pattern2 from '../../../assets/qr-pattern/pattern-2.png'
import pattern3 from '../../../assets/qr-pattern/pattern-3.png'
import pattern4 from '../../../assets/qr-pattern/pattern-4.png'
import pattern5 from '../../../assets/qr-pattern/pattern-5.png'
import pattern6 from '../../../assets/qr-pattern/pattern-6.png'
import corner2 from '../../../assets/qr-corners/corner-2.png'
import corner3 from '../../../assets/qr-corners/corner-3.png'
import corner4 from '../../../assets/qr-corners/corner-4.png'
import corner5 from '../../../assets/qr-corners/corner-5.png'
import corner6 from '../../../assets/qr-corners/corner-6.png'
import corner7 from '../../../assets/qr-corners/corner-7.png'
import corner8 from '../../../assets/qr-corners/corner-8.png'
import corner9 from '../../../assets/qr-corners/corner-9.png'

import usePlan from '../../../hooks/usePlan'
import LogoSelection from './LogoSelection'
import CustomLogos from './CustomLogos'

const PRESET = [
	'#000000',
	'#de3121',
	'#ef8000',
	'#198639',
	'#229ce0',
	'#2a5bd7',
	'#6b52d1',
	'#d84280',
]

const PATTERNS = [
	{
		id: 'classy',
		url: pattern1,
		title: 'Classy',
	},
	{
		id: 'rounded',
		url: pattern2,
		title: 'Rounded',
	},
	{
		id: 'extra-rounded',
		url: pattern3,
		title: 'Extra Rounded',
	},
	{
		id: 'classy-rounded',
		url: pattern4,
		title: 'Classy Rounded',
	},
	{
		id: 'square',
		url: pattern5,
		title: 'Square',
	},
	{
		id: 'dots',
		url: pattern6,
		title: 'Dots',
	},
]

const CORNERS = [
	{
		id: 'square',
		url: corner2,
		title: 'Square',
	},
	{
		id: 'extra-rounded',
		url: corner3,
		title: 'Extra Rounded',
	},
	{
		id: 'none',
		url: corner7,
		title: 'None',
	},
	{
		id: '8',
		url: corner8,
		title: 'Corner 8',
	},
	{
		id: '4',
		url: corner4,
		title: 'Corner 4',
	},
	{
		id: '5',
		url: corner5,
		title: 'Corner 5',
	},
	{
		id: 'dot',
		url: corner6,
		title: 'dot',
	},
	{
		id: '9',
		url: corner9,
		title: 'Corner 9',
	},
]

const CustomQr = ({ activeId, formData, setFormData, isEdit }) => {
	const { quota, disable } = useContext(AuthContxt)
	const {
		frame,
		preset,
		pattern,
		corner,
		logoToShow,
		plan,
		imagesUrl,
		newImagesAllowed,
	} = usePlan()
	//   const { setValue, watch,value } = form;
	const [file, setFile] = useState(null)
	const [uploadedImage, setUploadedImage] = useState()
	const [cropperModal, setCropperModal] = useState(false)
	const [logoBackground, setLogoBackground] = useState(false)

	const [errorMsg, setErrorMsg] = useState({})
	const [activeClass, setActiveClass] = useState('')

	const allowQr = quota?.qrCode

	const arePatternsLimited = quota?.pattern === 0 || false
	const isCustomLogo = quota?.customLogo === 1 || false
	const notColorOptions = quota?.colorOptions === 0 || false

	const [tooltipOpen, setTooltipOpen] = useState(null)
	const [cornerTooltipOpen, setCornerTooltipOpen] = useState(null)
	const { render, setRender } = useContext(AuthContxt)
	const [activeIcon, setActiveIcon] = useState(SvgIcons[0].type)

	useEffect(() => {}, [render])

	const colorHandler = (key, icon) => {
		if (key === 'frame') {
			// Set active icon: use `null` if `icon` is null (for default icon case)
			setActiveIcon(icon ? icon.type : null)
		}

		// Update form data
		setFormData((prev) => ({ ...prev, [key]: icon }))

		// Toggle render state if key is not 'frame'
		if (key !== 'frame') {
			setRender((prev) => !prev)
		}
	}

	const fileInputRef = useRef(null)

	const handlePenClick = () => {
		fileInputRef?.current?.click()
	}

	const resetLogoHandler = () => {
		setFile(null)
		setFormData((prev) => ({
			...prev,
			logo: '',
			qrLogoId: process.env.REACT_APP_QR_LOGO_ID || '',
		}))
		setRender(!render)
	}

	const createImage = (url) =>
		new Promise((resolve, reject) => {
			const image = new Image()
			image.addEventListener('load', () => resolve(image))
			image.addEventListener('error', (error) => reject(error))
			image.setAttribute('crossOrigin', 'anonymous')
			image.src = url
		})

	const getCroppedImg = async (imageSrc, pixelCrop, background) => {
		const image = await createImage(imageSrc)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		if (!ctx) {
			return null
		}
		canvas.width = image.width
		canvas.height = image.height

		ctx.drawImage(image, 0, 0)
		const data = ctx.getImageData(
			pixelCrop.x,
			pixelCrop.y,
			pixelCrop.width,
			pixelCrop.height
		)

		canvas.width = pixelCrop.width
		canvas.height = pixelCrop.height

		ctx.putImageData(data, 0, 0)

		return new Promise((resolve, reject) => {
			canvas.toBlob((file) => {
				const reader = new FileReader()
				reader.onload = function () {
					if (background) {
						let img = new Image()
						img.onload = () => {
							const base64data = addLogobackground(img)
							resolve(base64data)
						}
						img.src = reader.result
					} else {
						const base64data = reader.result
						resolve(base64data)
					}
				}
				reader.readAsDataURL(file)
			}, 'image/png')
		})
	}

	const uploadQrLogoHandler = (img) => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			QrCodesAPI.uploadQrCodeLogo(auth_token, { payload: img })
				.then(({ data }) => {
					setFormData((prev) => ({ ...prev, logo: img, qrLogoId: data.data }))
					setRender(!render)
				})
				.catch((error) => {
					console.log('Error while creating qr code: ', error)
				})
		} catch (e) {
			console.log('Error while uploading qr code: ', e)
		}
	}
	const handleDefaultLogos = (event) => {
		uploadQrLogoHandler(event)
	}
	const showCroppedImage = useCallback(
		async (image, croppedAreaPixels, background) => {
			try {
				const croppedImage = await getCroppedImg(
					image,
					croppedAreaPixels,
					background
				)
				uploadQrLogoHandler(croppedImage)
			} catch (e) {
				console.error(e)
			}
		},
		[]
	)

	const addLogobackground = (img) => {
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const padding = 10
		canvas.width = img.width + 2 * padding
		canvas.height = img.height + 2 * padding

		if (img.width === img.height) {
			const backgroundRadius = Math.min(canvas.width / 2, canvas.height / 2)
			ctx.beginPath()
			ctx.arc(
				canvas.width / 2,
				canvas.height / 2,
				backgroundRadius,
				0,
				2 * Math.PI
			)
			ctx.fillStyle = 'white'
			ctx.fill()
			ctx.closePath()
		} else {
			ctx.fillStyle = 'white'
			ctx.fillRect(0, 0, canvas.width, canvas.height)
		}

		ctx.drawImage(img, padding, padding, img.width, img.height)
		const logoWithBackground = canvas.toDataURL(img.type)
		return logoWithBackground
	}

	const handleFileChange = () => {
		if (file) {
			const reader = new FileReader()
			reader.onload = () => {
				let img = new Image()
				img.onload = () => {
					const base64String = logoBackground
						? addLogobackground(img)
						: reader.result
					uploadQrLogoHandler(base64String)
				}
				img.src = reader.result
			}
			reader.readAsDataURL(file)
		}
	}

	const resetErrorMsg = (type) => {
		setErrorMsg((preState) => ({
			...preState,
			[type]: null,
		}))
	}

	const cropModalHandler = (event) => {
		const selectedFile = event.target.files[0]
		const maxAllowedSize = 2 * 1024 * 1024
		if (event.target.files[0].size > maxAllowedSize) {
			event.target.value = ''
			setErrorMsg((preState) => ({
				...preState,
				image: 'Max allowed side is 2 MB',
			}))
			setTimeout(() => resetErrorMsg('image'), 4 * 1000)
			return
		}

		resetErrorMsg('image')

		if (selectedFile) {
			setFile(selectedFile)
			const reader = new FileReader()
			reader.onload = () => {
				const base64String = reader.result
				setUploadedImage(base64String)

				setCropperModal(true)
			}
			reader.readAsDataURL(selectedFile)
		}
		event.target.value = ''
	}

	useEffect(() => {
		const scrollHandler = () => {
			setActiveClass('scrolled_view_qr')
		}
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', scrollHandler)
		}
		return () => {
			window.removeEventListener('scroll', scrollHandler)
		}
	}, [])

	useEffect(() => {
		const scrollEndHandler = () => {
			setActiveClass('')
		}
		if (typeof window !== 'undefined') {
			window.addEventListener('scrollend', scrollEndHandler)
		}
		return () => {
			window.removeEventListener('scrollend', scrollEndHandler)
		}
	}, [])

	const PATTERN_IDS = PATTERNS.map((item) => item?.id)
	const CORNERS_IDS = CORNERS.map((item) => item?.id)

	const toggle = (val) => {
		if (PATTERN_IDS.includes(val)) {
			setTooltipOpen(val)
		}
	}

	const cornerToggle = (val) => {
		if (CORNERS_IDS.includes(val)) {
			setCornerTooltipOpen(val)
		}
	}

	useEffect(() => {
		console.log('CustomQr - Rendered formData:', formData)
	}, [formData])

	return (
		<>
			<TabPane tabId={activeId}>
				<div className="card-body px-0 mw-1000px pb-0 pt-1">
					<div className="separator border-info mt-lg-5 mb-lg-8 mb-4 border-opacity-10 mobile-hide"></div>
					<Row>
						<Col sm="12">
							<div className="rounded-1">
								<div>
									<div className="separator border-info mt-lg-5 mb-lg-8 mb-4 border-opacity-10 desktop-hide"></div>
									<h2 className="fw-bolder fs-2">Choose Frame</h2>
									<div className="separator my-5"></div>
									<div className="mb-5">
										{/* svg conditional restriction */}
										<div className="d-flex flex-wrap">
											{SvgIcons.map((icon, index) => (
												<div
													key={icon.type}
													className={`svg-item ${
														activeIcon === icon.type ? 'active' : ''
													}`}
												>
													{index < frame ? (
														<div
															className="p-3 h-100px w-100px d-flex justify-content-center align-items-center me-5 mb-5 rounded-1 btn on-hvr-border bdr-qr-btn"
															onClick={() => colorHandler('frame', icon)}
															style={{ backgroundColor: '#e9e4f3' }}
														>
															{icon.svg}
														</div>
													) : (
														<div
															className="p-3 h-100px w-100px d-flex justify-content-center align-items-center me-5 mb-5 rounded-1 btn on-hvr-border bdr-qr-btn opacity-10"
															style={{ backgroundColor: '#e9e4f3' }}
														>
															{icon.svg}
														</div>
													)}
												</div>
											))}
										</div>
									</div>
									{activeIcon !== 'icon0' && (
										<div className="d-flex flex-wrap gap-lg-10 gap-5 mb-15">
											<div className="mt-5">
												<div
													style={{
														display: 'flex',
														alignItems: 'baseline',
													}}
												>
													<p className="fw-semibold fs-4">Primary Color</p>
												</div>
												<div className="color-picker-wrapper">
													<Input
														onChange={(e) =>
															colorHandler('primary', e.target.value)
														}
														className="color-picker border-0 rounded-0 cursor-pointer"
														value={formData?.primary}
														type="color"
													/>
													<Input
														onChange={(e) =>
															colorHandler('primary', e.target.value)
														}
														className="hex-input form-control rounded-0"
														type="text"
														maxLength="7"
														value={formData?.primary}
														placeholder="#000000"
													/>
												</div>
											</div>
											{formData?.frame?.dual && (
												<div className="mt-5">
													<div
														style={{
															display: 'flex',
															alignItems: 'baseline',
														}}
													>
														<p className="fw-semibold fs-4">Secondary Color</p>
													</div>
													<div className="color-picker-wrapper flex-wrap gap-5">
														<div className="d-flex">
															<Input
																onChange={(e) =>
																	colorHandler('secondary', e.target.value)
																}
																className="color-picker border-0 rounded-0 cursor-pointer"
																value={formData?.secondary}
																type="color"
															/>
															<Input
																onChange={(e) =>
																	colorHandler('secondary', e.target.value)
																}
																className="hex-input form-control rounded-0"
																type="text"
																maxlength="7"
																value={formData?.secondary}
																placeholder="#000000"
															/>
														</div>
													</div>
												</div>
											)}
											<div className="mt-5">
												<div
													style={{
														display: 'flex',
														alignItems: 'baseline',
													}}
												>
													<p className="fw-semibold fs-4">Text Color</p>
												</div>
												<div className="color-picker-wrapper">
													<Input
														onChange={(e) =>
															colorHandler('textColor', e.target.value)
														}
														className="color-picker border rounded-0 cursor-pointer"
														value={formData?.textColor}
														type="color"
													/>
													<Input
														onChange={(e) =>
															colorHandler('textColor', e.target.value)
														}
														className="hex-input form-control rounded-0"
														type="text"
														maxLength="7"
														value={formData?.textColor}
														placeholder="#000000"
													/>
												</div>
											</div>
											<div className="mt-5">
												<div
													style={{
														display: 'flex',
														alignItems: 'baseline',
													}}
												>
													<p className="fw-semibold fs-4">Text</p>
												</div>
												<div className="color-picker-wrapper">
													<Input
														onChange={(e) =>
															colorHandler('text', e.target.value)
														}
														className="hex-input form-control rounded-0"
														type="text"
														maxLength="24"
														value={formData?.text}
														placeholder="Scan Me"
													/>
												</div>
											</div>
										</div>
									)}
									<div className="separator border-info mt-lg-5 mb-lg-8 mb-4 border-opacity-10 desktop-hide"></div>
									<h2 className="fw-bolder fs-2">Choose QR Code color</h2>
									<div className="separator my-5"></div>
									<div className="mb-5">
										<p className="fw-semibold fs-4 mb-4">Preset</p>
										<div className="d-flex flex-wrap row-gap-5">
											{PRESET.map((item, index) => {
												return (
													<>
														{index < preset ? (
															<div
																key={index}
																onClick={() => {
																	setFormData((prev) => ({
																		...prev,
																		preset: item,
																		color: item,
																	}))
																	setRender(!render)
																}}
																className={`p-1 cursor-pointer rounded-1 on-hvr-border bdr-qr-btn me-5 ${
																	item === formData?.preset ? ' selected' : ''
																}`}
															>
																<div
																	className="p-3 rounded-1 w-40px h-40px"
																	style={{
																		backgroundColor: item,
																	}}
																></div>
															</div>
														) : (
															<>
																<div
																	key={index}
																	className={`p-1  cursor-no-drop rounded-1 on-hvr-border bdr-qr-btn me-5 	  ${
																		item === formData?.preset ? ' selected' : ''
																	}`}
																>
																	<div
																		className="p-3 rounded-1 w-40px h-40px opacity-25 "
																		style={{
																			backgroundColor: item,
																		}}
																	></div>
																</div>
															</>
														)}
													</>
												)
											})}
										</div>
									</div>
									<div className="d-flex flex-wrap gap-lg-10 gap-5">
										<div className="mt-5">
											<div
												style={{
													display: 'flex',
													alignItems: 'baseline',
												}}
											>
												<p className="fw-semibold fs-4">Color</p>

												{plan && plan !== 'super' ? (
													<i className="fas ms-2 fa-lock"></i>
												) : null}
											</div>
											{plan && plan !== 'super' ? (
												<div className="color-picker-wrapper">
													<Input
														onChange={(e) =>
															!notColorOptions &&
															colorHandler('color', e.target.value)
														}
														disabled={notColorOptions}
														className="color-picker rounded-0 cursor-pointer"
														value={formData?.color}
														type="color"
													/>
													<Input
														onChange={(e) =>
															colorHandler('color', e.target.value)
														}
														className="hex-input form-control rounded-0"
														type="text"
														maxLength="7"
														value={formData?.color}
														disabled
														placeholder="#000000"
													/>
												</div>
											) : (
												<div className="color-picker-wrapper">
													<Input
														onChange={(e) =>
															!notColorOptions &&
															colorHandler('color', e.target.value)
														}
														disabled={notColorOptions}
														className="color-picker rounded-0 cursor-pointer"
														value={formData?.color}
														type="color"
													/>
													<Input
														onChange={(e) =>
															colorHandler('color', e.target.value)
														}
														className="hex-input form-control rounded-0"
														type="text"
														maxLength="7"
														value={formData?.color}
														// disabled
														placeholder="#000000"
													/>
												</div>
											)}
										</div>
										<div className="mt-5">
											<div
												style={{
													display: 'flex',
													alignItems: 'baseline',
												}}
											>
												<p className="fw-semibold fs-4">Background Color</p>

												{plan && plan != 'super' ? (
													<i className="fas ms-2 fa-lock"></i>
												) : null}
											</div>
											<div className="color-picker-wrapper flex-wrap gap-5">
												{plan && plan != 'super' ? (
													<div className="d-flex">
														<Input
															onChange={(e) =>
																!notColorOptions &&
																colorHandler('bgColor', e.target.value)
															}
															disabled={notColorOptions}
															className="color-picker rounded-0 cursor-pointer"
															value={formData?.bgColor}
															type="color"
														/>
														<Input
															onChange={(e) =>
																colorHandler('bgColor', e.target.value)
															}
															className="hex-input form-control rounded-0"
															type="text"
															maxlength="7"
															value={formData?.bgColor}
															disabled
															placeholder="#000000"
														/>
													</div>
												) : (
													<div className="d-flex">
														<Input
															onChange={(e) =>
																!notColorOptions &&
																colorHandler('bgColor', e.target.value)
															}
															disabled={notColorOptions}
															className="color-picker rounded-0 cursor-pointer"
															value={formData?.bgColor}
															type="color"
														/>
														<Input
															onChange={(e) =>
																colorHandler('bgColor', e.target.value)
															}
															className="hex-input form-control rounded-0"
															type="text"
															maxlength="7"
															value={formData?.bgColor}
															// disabled
															placeholder="#000000"
														/>
													</div>
												)}
												<div className="form-check ps-lg-5 ps-0">
													<div className="form-check form-check-sm form-check-custom form-check-info form-check-solid rounded-1">
														{plan && plan !== 'super' ? (
															<Input
																className="form-check-input rounded-1 cursor-pointer"
																type="checkbox"
																disabled
															/>
														) : (
															<Input
																className="form-check-input rounded-1 cursor-pointer"
																type="checkbox"
																onChange={() => {
																	setFormData((prev) => ({
																		...prev,
																		isBgTransparent: !formData?.isBgTransparent,
																	}))
																	setRender(!render)
																}}
															/>
														)}
														<label
															className="form-check-label"
															for="flexCheckDefault"
														>
															Transparent Background
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="separator my-10"></div>
									<div>
										<h2 className="fw-900 fs-2 mb-8">Choose the Style</h2>
										<div>
											<p className="fw-semibold fs-4">Patterns</p>
											<div>
												{PATTERNS?.map((item, index) => {
													return (
														<>
															{index < pattern ? (
																<div
																	key={item?.id}
																	className={`bg-white p-3 me-5 mb-lg-0 mb-5 rounded-1 btn ${'on-hvr-border'} bdr-qr-btn ${'selected'}`}
																>
																	<div
																		type="button"
																		onClick={() => {
																			if (true) {
																				setFormData((prev) => ({
																					...prev,
																					pattern: item.id,
																				}))
																				setRender(!render)
																			}
																		}}
																		onMouseOver={(e) => {
																			if (true) {
																				toggle(item.id)
																			}
																		}}
																		onMouseLeave={(e) => setTooltipOpen(null)}
																		id="pattern"
																	>
																		<img
																			className="w-50px h-50px"
																			src={item.url}
																			alt={item.title}
																		/>
																	</div>
																</div>
															) : (
																<div
																	key={item?.id}
																	className={`bg-white p-3 me-5 mb-lg-0 mb-5 rounded-1 btn opacity-50 cursor-no-drop`}
																>
																	<div type="button" id="pattern">
																		<img
																			className="w-50px h-50px opacity-25"
																			src={item.url}
																			alt={item.title}
																		/>
																	</div>
																</div>
															)}
														</>
													)
												})}
											</div>
											<div className="pt-lg-10 pt-5">
												<p className="fw-semibold fs-4 mb-4">Corners</p>
												<div>
													{CORNERS.map((item, index) => {
														return (
															<>
																{index < corner ? (
																	<div
																		type="button"
																		className={`bg-white p-3 m-2 ms-0 me-5 rounded-1 btn ${'on-hvr-border'} bdr-qr-btn ${'selected'}`}
																		onClick={() => {
																			if (true) {
																				setFormData((prev) => ({
																					...prev,
																					corner: item.id,
																				}))
																				setRender(!render)
																			}
																		}}
																		onMouseOver={(e) => {
																			if (true) {
																				cornerToggle(item.id)
																			}
																		}}
																		onMouseLeave={(e) =>
																			setCornerTooltipOpen(null)
																		}
																		id="corner"
																	>
																		<img
																			className="w-30px h-30px"
																			src={item.url}
																			alt={item.title}
																		/>
																	</div>
																) : (
																	<div
																		type="button"
																		className={`bg-white p-3 m-2 ms-0 me-5 rounded-1 btn opacity-50 cursor-no-drop`}
																		id="corner"
																	>
																		<img
																			className="w-30px h-30px opacity-25"
																			src={item.url}
																			alt={item.title}
																		/>
																	</div>
																)}
															</>
														)
													})}
												</div>
											</div>
											<div className="separator my-10"></div>

											<div>
												<h2 className="fw-900 fs-2 mb-15">
													Choose your Logo{' '}
													{plan === 'starter' ? (
														<i className="fas ms-2 fa-lock"></i>
													) : null}
												</h2>
												<div className="d-flex flex-wrap justify-content-start">
													<div className="image-input image-input-outline me-5 mb-5">
														<div className="image-input-wrapper w-100px h-100px p-3 d-flex justify-content-center align-items-center">
															<img
																src={formData?.logo || logoWhiteBg}
																alt="Logo"
																height={85}
																width={85}
																onError={(e) => {
																	e.target.onerror = null // Prevents infinite onError loop
																	e.target.src = logoWhiteBg // Fallback to default logo
																}}
															/>
														</div>
													</div>

													<CustomLogos
														handleDefaultLogos={handleDefaultLogos}
														errorMsg={errorMsg}
													/>

													<LogoSelection
														newImagesAllowed={newImagesAllowed}
														handleDefaultLogos={handleDefaultLogos}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</TabPane>

			{cropperModal ? (
				<LogoCropper
					image={uploadedImage || '/logo-with-white-bg.png'}
					backgroundHandler={setLogoBackground}
					logoBackground={logoBackground}
					toggleHandler={() => {
						setCropperModal(false)
						handleFileChange()
					}}
					cancelHandler={() => {
						setCropperModal(false)
					}}
					cropHandler={async (area = null, pixels = null) => {
						pixels
							? await showCroppedImage(uploadedImage, pixels, logoBackground)
							: handleFileChange()
						setCropperModal(false)
					}}
				/>
			) : null}
		</>
	)
}

export default CustomQr
