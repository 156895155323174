import dayjs from 'dayjs'
import SharedApi from '../services/http/shared'

export const notFoundTitle = `Untitled ${dayjs(new Date()).format('YYYY-MM-DD')}`

// Validating the url
const checkUrl = (url) => {
	try {
		// If the URL starts with "www." but no protocol, prepend "http://"
		if (/^www\./i.test(url)) {
			url = `http://${url}`
		}

		// If the URL doesn't start with a protocol, prepend "http://"
		if (!/^((ftp|http|https):\/\/)/i.test(url)) {
			url = `http://${url}`
		}

		// Use the URL constructor to validate the URL
		const validUrl = new URL(url)

		// Validate the hostname to ensure it's a proper domain with TLD
		return /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(validUrl.hostname)
	} catch (error) {
		return false // Invalid URL
	}
}

const titleFetcher = async (url = '') => {
	const modifiedUrl = url.trim()
	const isValidUrl = checkUrl(modifiedUrl)
	if (!isValidUrl) return

	try {
		if (modifiedUrl) {
			const auth_token = localStorage.getItem('auth_token')
			const title = await SharedApi.fetchTitle(
				auth_token,
				!modifiedUrl.startsWith('https://')
					? `https://${modifiedUrl}`
					: modifiedUrl
			)
			return title?.data
		} else {
			return { title: '', metaDescription: '' }
		}
	} catch (error) {
		console.error('Error fetching title:', error.message)
		return notFoundTitle
	}
}

export default titleFetcher
